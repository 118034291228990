import { Component, Input, OnInit } from '@angular/core';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';

@Component({
  selector: 'app-accion-partido-miniatura',
  templateUrl: './accion-partido-miniatura.component.html',
  styleUrls: ['./accion-partido-miniatura.component.css']
})
export class AccionPartidoMiniaturaComponent implements OnInit {

  @Input()
  public accion: number;

  constructor(
    private enumeracionesService: EnumeracionesService) { }

  ngOnInit(): void {
  }

  obtenerAccionPartido(): string {
    return this.enumeracionesService.obtenerAccion(this.accion);
  }
}
