import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquiposService } from 'src/services/servicio.equipos';
import { SesionService } from 'src/services/servicio.sesion';

@Component({
  selector: 'app-equipo-eliminar',
  templateUrl: './equipo-eliminar.component.html',
  styleUrls: ['./equipo-eliminar.component.css']
})
export class EquipoEliminarComponent implements OnInit {
  public equipoId: any;
  public jugadorIdSesion: any;
  public equipo: any;
  public noAutorizado: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public equipoService: EquiposService,
    public sesionService: SesionService) { }

  ngOnInit(): void {
    this.noAutorizado = false;
    this.route.params.subscribe(params => {
      this.equipoId = params['id'];
      this.reload();
    });
  }

  private reload() : void {
    this.equipoService.getEquipo(this.equipoId).subscribe(res => {
      this.jugadorIdSesion = this.sesionService.obtenerSesion().usuarioAutenticado.entidades.filter(j => j.tipo == 0)[0].id;
      this.equipo = res.datos;
      if (!this.esJugadorCapitan(this.equipo)) {
        this.noAutorizado = true;
      }
    });
  }

  eliminar() : void {
    this.equipoService.eliminar(this.equipo).subscribe(res => {
      if (res.esCorrecto) {
        this.router.navigate(["/mi-perfil"]);
      }
      else {
        alert(res.mensajes[0]);
      }
    });
  }

  public esJugadorCapitan(equipo: any) : boolean {
    var jugador = this.equipo.nomina.find(j => j.jugador.id == this.jugadorIdSesion && j.esCapitan);
    return jugador ?? false;
  }

}
