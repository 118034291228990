<div class="container" style="background-color: white">
  <main id="main">
    <br>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <div class="wrap d-md-flex">
          <div class="img" style="background-image: url(/assets/img/panel-backgrounds/back_alemania.png);">
          </div>
          <div class="login-wrap p-4 p-md-5">
            <div class="d-flex">
              <div class="w-100">
                <h3 class="mb-4">Registro</h3>
              </div>
              <div class="w-100">
                <p class="social-media d-flex justify-content-end">
                  <a href="https://www.facebook.com/profile.php?id=100092621465273" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-facebook"></i></a>
                  <a href="https://twitter.com/tocala_cl" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-twitter"></i></a>
                  <a href="https://www.instagram.com/tocalachile/" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-instagram"></i></a>
                </p>
              </div>
            </div>
            <div *ngIf="this.error.esVisible == false" >
              <div *ngIf="!this.registroEnviado">
                <p>
                  Queremos un ambiente respetuoso, lo mínimo que debemos pedirte es el correo electrónico para poder enviarte
                  las invitaciones, cancelaciones o modificaciones que ocurran en los partidos que serás parte.
                </p>
                <p>
                  Pulsando en registrar aceptas nuestros términos de uso y política de privacidad de datos. No te enviaremos
                  ningún tipo de correo no deseado.
                </p>
                <p>
                  <b>Te enviaremos un correo con tu contraseña inicial.</b>
                </p>
                <div class="example-full-width" appearance="fill">
                  <label>Email</label>
                  <input [(ngModel)]="this.usuario.Email" type="text" class="form-control" style="text-transform: lowercase" placeholder="Email">
                </div>
                <button class="form-control btn btn-primary rounded submit px-3" (click)="registrar()" [disabled]="this.estaRegistrando" >
                  <div *ngIf="this.estaRegistrando" class="spinner-border text-warning" role="status">
                    <span class="sr-only"></span>
                  </div>
                  <span *ngIf="!this.estaRegistrando">Registrar</span>
                  </button>
                <br><br>
                <a [routerLink]="['/usuario/ingreso']" class="text-small forgot-password text-primary">Ir al Ingreso</a>
              </div>
              <div *ngIf="this.registroEnviado">
                <h4>Registro enviado...</h4>
                <br />
                <form>
                  <div class="form-group">
                    <p>Revise su correo electrónico para continuar con el registro.</p>
                  </div>
                </form>
                <div class="form-group d-flex justify-content-between">
                  <a [routerLink]="['/usuario/ingreso']" class="text-small forgot-password text-primary">Ir al Ingreso</a>
                </div>
              </div>
            </div>
            <div *ngIf="this.error.esVisible">
              <div *ngIf="error" class="card text-bg-danger">
                <div class="card-body">

                  <p>Tenemos problemas para completar su registro:</p>
                  <ul *ngFor="let mensajeError of this.error.mensajes">
                    <li><b>{{ mensajeError }}</b></li>
                  </ul>
                </div>
              </div>
              <div class="form-group d-flex justify-content-between">
                <br><br>
                <a [routerLink]="['/usuario/ingreso']" class="text-small forgot-password text-primary">Ir al Ingreso</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </main>
</div>