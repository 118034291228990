import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class PortalesModule { }
