import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class RecintosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGetSinAutentificacion() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getRecintos(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/recinto/Buscar`, { keywords: filtro.split(' '), largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getRecintosPorComuna(comuna: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/recinto/PorComuna`, { keywords: [ comuna ], largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getRecinto(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGetSinAutentificacion()
        };
        return this.http.get(`${this.urlApi}/recinto/${id}`, options);
    }

    public getMany(ids: Array<string>) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        let url = `${this.urlApi}/recinto/getmany/?ids=${ids.join(",")}`;
        console.log(url);
        return this.http.get(url, options);
    }
    
    public crear(recinto: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.put(`${this.urlApi}/recinto`, recinto, options);
    }
    
    public actualizar(recinto: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/recinto`, recinto, options);
    }
}