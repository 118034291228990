import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampeonatosService } from 'src/services/servicio.campeonatos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-campeonato-lista',
  templateUrl: './campeonato-lista.component.html',
  styleUrls: ['./campeonato-lista.component.css']
})
export class CampeonatoListaComponent implements OnInit {
  resultados: any;
  pagina: number;
  largoPagina: number;
  filtro: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private campeonatoService: CampeonatosService,
    private ubicacionesService: UbicacionesService) { }

  ngOnInit(): void {
    this.pagina = 1;
    this.largoPagina = 10;
    this.filtro = "";
    this.route.params.subscribe(params => {
      var pagina = params['pagina'];
      this.reload();
      
    });
  }

  
  reload() : void {
    this.campeonatoService.getCampeonatos(this.filtro, this.pagina, this.largoPagina).subscribe(res => {
      this.resultados = res;
      console.log(this.resultados.items);
    });
  }

  public obtenerUbicacion(ubicacionId: string) : any {
    return this.ubicacionesService.getUbicacion(ubicacionId);
  }


  paginadorListener(eventoPaginador: any) : void {
    console.log(eventoPaginador);
    eventoPaginador.previousPageIndex
    this.pagina = eventoPaginador.pageIndex + 1;
    this.largoPagina = eventoPaginador.pageSize;
    this.reload();
  }
  
  buscar() : void {
    this.pagina = 1;
    this.reload();    
  }
  
  public anterior(): void {
    if (this.pagina > 1)
    {
      this.pagina = this.pagina - 1;
      this.reload();
    }
  }

  public siguiente(): void {
    if (this.pagina > 0)
    {
      this.pagina = this.pagina + 1;
      this.reload();
    }
  }
}
