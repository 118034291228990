<div class="container px-4 py-5" id="custom-cards">
  <h2 class="pb-2 border-bottom">
    <span *ngIf="this.accion == 'crear'">Crear nuevo equipo</span>
    <span *ngIf="this.accion == 'modificar'">Modificando equipo</span>
  </h2>
  <form class="needs-validation" novalidate="">
    <div class="row g-2" *ngIf="this.equipo">
      <div class="col-sm-6">
        <label for="Alias" class="form-label">Alias</label>
        <div class="input-group has-validation">
          <input type="text" class="form-control" name="Alias" placeholder="'Chile', 'Argentina', etc"
            [(ngModel)]="this.equipo.alias" required="">
          <div class="invalid-feedback">
            Un alias corto es requerido.
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <label for="Nombre" class="form-label">Nombre</label>
        <input type="text" class="form-control" name="Nombre"
          placeholder="'Selección Chilena de Futbol', 'Selección Argentina de Futbol', etc"
          [(ngModel)]="this.equipo.nombre" required="">
        <div class="invalid-feedback">
          Un nombre valido es requerido
        </div>
      </div>

      <div class="col-sm-12">
        <label for="Emblema" class="form-label">Emblema</label>
        <input type="text" class="form-control" name="Emblema"
          placeholder="'Uno para todos y todos para uno', 'Hasta que se acaben las chelas', etc"
          [(ngModel)]="this.equipo.emblema" required="">
        <div class="invalid-feedback">
          Un emblema valido es requerido
        </div>
      </div>

      <div class="col-sm-12">
        <label for="Ubicacion" class="form-label">Ubicación</label>
        <select class="form-select" name="Ubicacion" required="" [(ngModel)]="this.equipo.ubicacion.comuna">
          <option *ngFor="let ubicacion of this.ubicacionesPosibles" [ngValue]="ubicacion.key">{{ubicacion.value}}
          </option>
        </select>
        <div class="invalid-feedback">
          Por favor seleccione alguna ubicación válida
        </div>
      </div>


      <div class="col-sm-6">
        <label for="Alias" class="form-label">Color Principal</label>
        <div class="input-group has-validation">
          <input type="color" class="form-control-color" [(ngModel)]="this.equipo.color" name="color"  required="">
          <div class="invalid-feedback">
            Un color es requerido.
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <label for="Nombre" class="form-label">Color Alternativo</label>
        <div class="input-group has-validation">
          <input type="color" class="form-control-color" [(ngModel)]="this.equipo.colorAlternativo" name="colorAlternativo" required="">
          <div class="invalid-feedback">
            Un color alternativo es requerido
          </div>
        </div>
      </div>

      <div class="card text-bg-danger" *ngIf="this.mensajesError && this.mensajesError.length > 0">
        <div class="card-header">Ups!</div>
        <div class="card-body">
            <h5 class="card-title">Encontramos algunos problemas</h5>
            <ul>
                <li *ngFor="let mensajeError of this.mensajesError">{{ mensajeError }}</li>
            </ul>
        </div>
    </div>

      <div class="col-sm-12">
        <button *ngIf="this.accion == 'crear'" class="btn btn-primary" (click)="crear()">Crear</button>
        <button *ngIf="this.accion == 'modificar'" class="btn btn-primary" (click)="modificar()">Guardar</button>
      </div>
    </div>
  </form>
</div>