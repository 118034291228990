import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/services/servicio.usuarios';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  public infoContacto: any;
  public resultadoValidacion: string;
  public estadoEnvioMensaje: string;

  constructor(private usuariosService: UsuariosService) { }

  ngOnInit(): void {
    this.infoContacto = {}
  }

  enviarContacto() : void {
    if (this.validarContacto()) {
      this.estadoEnvioMensaje = "enviando";
      this.usuariosService.contacto(this.infoContacto).subscribe(res => {
        this.infoContacto = {};
        this.estadoEnvioMensaje = "enviado";
      });
    }
    else {
      this.estadoEnvioMensaje = "error";
    }
  }

  validarContacto() : boolean {
    // this.infoContacto
    if (!this.infoContacto.nombreRemitente || this.infoContacto.nombreRemitente == '') {
      this.resultadoValidacion = "debe proporcionar un nombre";
      return false;
    }
    if (!this.infoContacto.emailRemitente || this.infoContacto.emailRemitente == '') {
      this.resultadoValidacion = "debe proporcionar un correo electrónico";
      return false;
    }
    if (!this.infoContacto.titulo || this.infoContacto.titulo == '') {
      this.resultadoValidacion = "Por favor indique brevemente de que se trata su mensaje en el asunto";
      return false;
    }
    if (!this.infoContacto.mensaje || this.infoContacto.mensaje == '') {
      this.resultadoValidacion = "Por favor escriba un mensaje";
      return false;
    }
    return true;
  }
}
