import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-equipo-editor',
  templateUrl: './equipo-editor.component.html',
  styleUrls: ['./equipo-editor.component.css']
})
export class EquipoEditorComponent implements OnInit {

  formularioEquipo: FormGroup;
  public accion: string;
  public id: string;
  public equipo: any;

  public ubicacionesPosibles: Array<any>;
  public comunasFiltradas: Array<any>;

  mensajesError: Array<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private equiposService: EquiposService,
    private jugadoresService: JugadoresService,
    private ubicacionesService: UbicacionesService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.ubicacionesPosibles = this.ubicacionesService.obtenerComunas();
      this.id = params['id'];
      this.accion = params['accion'];
      if (this.accion == "crear") {
        this.equipo = {
          nombre: "",
          alias: "",
          color: "#000000",
          colorAlternativo: "#000000",
          emblema: "",
          esVisible: true,
          urlEquipo: "",
          urlLogo: "",
          ubicacion: {
            comuna: "06-1-16",
            direccion: "",
            latitud: -34.170967,
            longitud: -70.7284
          }
        };
      }
      if (this.accion == "modificar") {
        this.equiposService.getEquipo(this.id).subscribe(res => {
          this.equipo = res.datos;
          console.log(this.equipo);
        });
      }
    });
  }

  validar(): boolean {
    this.mensajesError = [];

    if (this.equipo.nombre == "") {
      this.mensajesError.push("Debe ingresar un nombre para el equipo");
    }

    if (this.equipo.alias == "") {
      this.mensajesError.push("Escriba un alias corto que permita mostrar bien el nombre de su equipo en el marcador");
    }

    if (this.mensajesError.length > 0) {
      return false;
    }

    return true;

  }

  public crear(): void {
    if (this.validar()) {
      this.equiposService.crear(this.equipo).subscribe(resp => {
        console.log(resp);
        if (resp.esCorrecto) {
          this.equipo = resp.datos;
          this.jugadoresService.borrarCache();
          this.router.navigate(["/equipo", this.equipo.id]);
          console.log("correcto", resp.datos);
        }
      });
    }

  }

  public modificar(): void {
    if (this.validar()) {
      this.equiposService.actualizar(this.equipo).subscribe(resp => {
        console.log(resp);
        if (resp.esCorrecto) {
          this.router.navigate(["/equipo", this.equipo.id]);
          console.log("correcto", resp.datos);
        }
      });
    }
  }
}