<!-- ======= Hero Section ======= -->
<section id="hero" class="hero">
    <div class="container position-relative">
        <div class="row gy-5" data-aos="fade-in">
            <div
                class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                <h2>Bienvenido a <span>Tocala.cl</span></h2>
                <p>Organizar partidos era un problema hasta ahora, en Tocala puedes crear tus propios equipos,
                    buscar parches, desafiar rivales y mucho más...</p>
                <div class="d-flex justify-content-center justify-content-lg-start">
                    <a [routerLink]="['/usuario/ingreso']" class="btn-get-started">Comenzar</a>
                    <a [routerLink]="['/info/articulo', 'como-funciona']"
                        class="glightbox btn-watch-video d-flex align-items-center"><i
                            class="bi bi-play-circle"></i><span>¿Cómo funciona?</span></a>
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/celular.png" style="height: 600px;" class="img-fluid" alt="" data-aos="zoom-out">
            </div>
        </div>
    </div>

    <div class="icon-boxes position-relative">
        <div class="container position-relative">
            <div class="row gy-4 mt-5">

                <div class="col-xl-3 col-sm-6">
                    <div class="icon-box">
                        <div class="icon"><i class="fa-solid fa-id-card"></i></div>
                        <h4 class="title"><a [routerLink]="['/info/articulo', 'crear-nueva-cuenta']" class="stretched-link">Registrate</a></h4>
                        <a style="color: #ffffff" [routerLink]="['/info/articulo', 'crear-nueva-cuenta']">¿Cómo lo hago?</a>
                    </div>
                </div>
                <!--End Icon Box -->

                <div class="col-xl-3 col-sm-6">
                    <div class="icon-box">
                        <div class="icon"><i class="fa-solid fa-user-group"></i></div>
                        <h4 class="title"><a [routerLink]="['/info/articulo', 'crear-equipo']" class="stretched-link">Invita</a></h4>
                        <a style="color: #ffffff" [routerLink]="['/info/articulo', 'crear-equipo']">¿Cómo lo hago?</a>
                    </div>
                </div>
                <!--End Icon Box -->

                <div class="col-xl-3 col-sm-6">
                    <div class="icon-box">
                        <div class="icon"><i class="fa-sharp fa-solid fa-calendar-check"></i></div>
                        <h4 class="title"><a [routerLink]="['/info/articulo', 'crear-partido']" class="stretched-link">Organiza</a></h4>
                        <a style="color: #ffffff" [routerLink]="['/info/articulo', 'crear-partido']">¿Cómo lo hago?</a>
                    </div>
                </div>
                <!--End Icon Box -->

                <div class="col-xl-3 col-sm-6">
                    <div class="icon-box">
                        <div class="icon"><i class="fa-solid fa-futbol"></i></div>
                        <h4 class="title"><a href="" class="stretched-link">Juega</a></h4>
                    </div>
                </div>
                <!--End Icon Box -->

            </div>
        </div>
    </div>
</section>
<!-- End Hero Section -->

<main id="main">

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
        <div class="container">

            <div class="section-header">
                <h2>¿Que es tocala.cl?</h2>
            </div>

            <div class="row gy-4">
                <div class="col-lg-6">
                    <p class="fst-italic">
                        Este es un esfuerzo que intenta fomentar el deporte, y particularmente el fútbol
                        amateur, proporcionándole herramientas de organización,
                        comunicación e información que estimulen su práctica constante.
                    </p>
                    <ul>
                        <li><i class="bi bi-check-circle-fill"></i> Acercando jugadores y equipos en un mismo
                            lugar.</li>
                        <li><i class="bi bi-check-circle-fill"></i> Conectando equipos para realizar partidos.
                        </li>
                        <li><i class="bi bi-check-circle-fill"></i> Proporcionandoles a todos los usuarios
                            igualdad de oportunidades.</li>
                    </ul>

                    <p>
                        El servicio no tiene costo para personas naturales pues esta pensado para ser economico de mantener en
                        funcionamiento. Está alojado en Google Cloud Platform y está
                        sponsoreado por <a target="_blank" href="condorerp.cl">Condor ERP</a> de <a target="_blank"
                            href="lintu.cl">Lintu</a>. Si puede prefiera sus
                        servicios pues financian este tipo de iniciativas.

                    </p>
                    <p>
                        Organizar partidos nunca fue tan fácil...
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="content ps-0 ps-lg-5">
                        <div class="position-relative mt-4">
                            <img src="assets/img/Tutorial.png" class="img-fluid rounded-4" alt="">
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section><!-- End About Us Section -->

    <!-- ======= Call To Action Section ======= -->
    <section id="call-to-action" class="call-to-action">
        <div class="container text-center" data-aos="zoom-out">
            <h3>Quiero aprender más...</h3>
            <p>En los distintos articulos de ayuda te explicamos que puedes hacer con el servicio.</p>
            <a class="cta-btn" [routerLink]="['/info/articulos-lista/', 'ayuda']">Ir a la ayuda</a>
        </div>
    </section><!-- End Call To Action Section -->

    <!-- ======= Testimonials Section ======= -->
    <section id="testimonials" class="testimonials">
        <div class="container">

            <div class="section-header">
                <h2>Testimonios</h2>
                <p>Algunos usuarios nos expresan su amor por los mensajes de contacto y en nuestras redes
                    sociales,<br /> sumate a la buena onda y déjanos tu testimonio.</p>
            </div>

            <div class="row">
            
                <div class="col-sm-6 col-md-6 col-lg-3 swiper-slide">
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <div class="d-flex align-items-center">
                                <img src="assets/template/img/testimonials/tanchi.png"
                                    class="testimonial-img flex-shrink-0" alt="">
                                <div>
                                    <h3>Francisco Lillo</h3>
                                    <h4>Jugador</h4>
                                </div>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                Organizar un partido siempre es una tarea difícil, los jugadores deben saber
                                dónde llegar, a que hora, de que color debe ser la camiseta, etc. Todo esto
                                parece ahora un problema del pasado, muchas gracias!
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="col-sm-6 col-md-6 col-lg-3 swiper-slide">
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <div class="d-flex align-items-center">
                                <img src="assets/template/img/testimonials/capitano.png"
                                    class="testimonial-img flex-shrink-0" alt="">
                                <div>
                                    <h3>Felipe Espinoza</h3>
                                    <h4>Jugador</h4>
                                </div>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                Yo deje de organizar partidos porque siempre se me caian los jugadores a ultima
                                hora y tenia que andar solucionando el problema sobre la marcha.
                                Hace unos días ocurrió de nuevo e invitamos a un parche con un par de clicks,
                                esa es.
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="col-sm-6 col-md-6 col-lg-3 swiper-slide">
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <div class="d-flex align-items-center">
                                <img src="assets/template/img/testimonials/yo.png"
                                    class="testimonial-img flex-shrink-0" alt="">
                                <div>
                                    <h3>Edgardo Ortiz</h3>
                                    <h4>Creador</h4>
                                </div>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                Hice el servicio que queria que existiera cuando tenia 16 años. En este mundo
                                tan apurado,
                                donde encontrar tiempo para practicar deporte puede ser un desafío, intentamos
                                aportar con un grano de
                                arena para facilitar la organización del deporte amateur. Esta hecho con mucho
                                amor, espero que lo disfruten.
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="col-sm-6 col-md-6 col-lg-3 swiper-slide">
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <div class="d-flex align-items-center">
                                <img src="assets/template/img/testimonials/navarro.png"
                                    class="testimonial-img flex-shrink-0" alt="">
                                <div>
                                    <h3>Rodrigo Navarro</h3>
                                    <h4>Capitán Corazón Roto F.C.</h4>
                                </div>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                En Corazon Roto Fuimos los primeros en probar la funcionalidad de estadísticas,
                                pudimos saber quien era el que perdía mas el balón, quien era el que mas
                                pateaba, etc. Tomamos decisiones basados en datos. Experiencia única para el
                                amateurismo.
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </div><!-- End testimonial item -->
            </div>

        </div>
    </section><!-- End Testimonials Section -->

    <!-- ======= Frequently Asked Questions Section ======= -->
    <section id="faq" class="faq sections-bg">
        <div class="container">

            <div class="row gy-4">

                <div class="col-lg-4">
                    <div class="content px-xl-5">
                        <h3>Preguntas <strong>Frecuentes</strong></h3>
                        <p>
                            Preguntas que siempre recibimos por distintos canales y que merecen una buena respuesta
                        </p>
                    </div>
                </div>

                <div class="col-lg-8">

                    <div class="accordion accordion-flush" id="faqlist">

                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-1">
                                    <span class="num">1.</span>
                                    ¿Hay algún pago asociado al uso de este servicio?
                                </button>
                            </h3>
                            <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    No hay un pago asociado para el uso personal, esto quiere decir que el servicio
                                    es gratis para todos aquellos que organicen como máximo 1 partido por dia.
                                </div>
                            </div>
                        </div><!-- # Faq item-->

                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-2">
                                    <span class="num">2.</span>
                                    ¿Necesito una tarjeta de crédito para utilizar este servicio?
                                </button>
                            </h3>
                            <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    No.
                                </div>
                            </div>
                        </div><!-- # Faq item-->

                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-3">
                                    <span class="num">3.</span>
                                    ¿Necesito un equipo para poder registrarme?
                                </button>
                            </h3>
                            <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    No, de hecho, puedes comenzar tu propio equipo despues de registrarte. Una vez
                                    que armes tu equipo, puedes empezar a organizar partidos.
                                    Tus amigos deberán esta registrados de antemano para que puedas agregarlos en tu
                                    equipo.
                                </div>
                            </div>
                        </div><!-- # Faq item-->

                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-4">
                                    <span class="num">4.</span>
                                    Veo información maliciosa o incorrecta en el sitio, ¿que debo hacer?
                                </button>
                            </h3>
                            <div id="faq-content-4" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    Este servicio es gratuito y a causa de ello es tan autoadministrado como es
                                    posible, no existen moderadores verificando el contenido, por tanto rogamos a la
                                    comunidad a enviarnos un correo de contacto con la información necesaria para
                                    poder atender el problema
                                </div>
                            </div>
                        </div><!-- # Faq item-->
                    </div>

                </div>
            </div>

        </div>
    </section><!-- End Frequently Asked Questions Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
        <div class="container">

            <div class="section-header">
                <h2>Contacto</h2>
               <p>Con tu participación mejoramos...</p>
            </div>

            <div class="row gx-lg-0 gy-4">

                <div class="col-lg-4">

                    <div class="info-container d-flex flex-column align-items-center justify-content-center">
                        <div class="info-item d-flex">
                            <div>
                                <p>¿Dudas del servicio, precio, disponibilidad?, ¿Sugerencias?, ¿Reclamos?. Estamos aquí para
                                ayudarte, dejanos un mensaje y te contactaremos a la brevedad.</p>
                            </div>
                        </div>
                        <div class="info-item d-flex">
                            <i class="fa-sharp fa-solid fa-location-dot"></i>
                            <div>
                                <h4>Requínoa</h4>
                                <p>VI Región</p>
                            </div>
                        </div><!-- End Info Item -->
                    </div>

                </div>

                <div class="col-lg-8">
                    <form method="post" role="form" class="php-email-form">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input type="text" name="nombreRemitente" class="form-control" placeholder="Su nombre" [(ngModel)]="this.infoContacto.nombreRemitente"
                                    required>
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" class="form-control" name="emailRemitente" [(ngModel)]="this.infoContacto.emailRemitente"
                                    placeholder="Su email" required>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <input type="text" class="form-control" name="titulo" placeholder="Asunto"  [(ngModel)]="this.infoContacto.titulo"
                                required>
                        </div>
                        <div class="form-group mt-3">
                            <textarea class="form-control" name="mensaje" rows="7" placeholder="Mensaje"   [(ngModel)]="this.infoContacto.mensaje"
                                required></textarea>
                        </div>
                        <div class="my-3">
                            <div *ngIf="this.estadoEnvioMensaje == 'enviando'" class="loading">Loading</div>
                            <div *ngIf="this.estadoEnvioMensaje == 'error'" class="error-message">{{ this.resultadoValidacion }}</div>
                            <div *ngIf="this.estadoEnvioMensaje == 'enviado'" class="sent-message">Su mensaje fue enviado, muchas gracias!</div>
                        </div>
                        <div class="text-center"><button (click)="enviarContacto()">Enviar</button></div>
                    </form>
                </div><!-- End Contact Form -->

            </div>

        </div>
    </section><!-- End Contact Section -->

</main><!-- End #main -->