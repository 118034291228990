import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';
import { RecintosService } from 'src/services/servicio.recintos';

@Component({
  selector: 'app-recinto-editor',
  templateUrl: './recinto-editor.component.html',
  styleUrls: ['./recinto-editor.component.css']
})
export class RecintoEditorComponent implements OnInit {

  public id: string;
  public recinto: any;
  public accion: string;
  public ubicacionesPosibles: Array<any>;

  constructor(
    private recintoService: RecintosService,
    private route: ActivatedRoute,
    private router: Router,
    private ubicacionesService: UbicacionesService,
  ) { }

  ngOnInit(): void {
    this.ubicacionesPosibles = this.ubicacionesService.obtenerComunas();
    this.route.params.subscribe(params => {
      this.accion = params['accion'];
      if (this.accion == "crear") {
        this.id = uuidv4();
        this.recinto = { 
          id: this.id,  
          nombre: '', 
          descripcion: '', 
          esVisible: true, 
          ubicacion: { 
            direccion: '', 
            comuna: '', 
            latitud: 0, 
            longitud: 0 
          }
        };
      }
      else if (this.accion == "modificar") {
        this.id = params['id'];
      }
    });
  }

  public validar() : boolean {
    return true;
  }

  public guardar() : void {
    if (this.validar()) {
      if (this.accion == 'crear') {
        this.recintoService.crear(this.recinto).subscribe(res => {
          if (res.esCorrecto) {
            this.router.navigate(["/recinto", this.id]);
          }
          else {
            alert("Hubo un error, vea la consola para ver el partido que falló");
            console.log(this.recinto);
          }
        })
      }
      else {
        this.recinto.ultimaModificacion = new Date();
        this.recintoService.actualizar(this.recinto).subscribe(res => {
          if (res.esCorrecto) {
            this.router.navigate(["/recinto", this.id]);
          }
          else {
            alert("Hubo un error, vea la consola para ver el partido que falló");
            console.log(this.recinto);
          }
        })
      }
    }
  }
}
