
  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center" style="background-image: url('');">
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>Posts: <strong>{{ this.keyword }}</strong></h2>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li><a href="/">Inicio</a></li>
            <li>Articulos</li>
          </ol>
        </div>
      </nav>
    </div><!-- End Breadcrumbs -->

    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row" *ngIf="!this.articulos">
          <div class="spinner-border text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="row gy-4 posts-list">

          <div *ngFor="let articulo of this.articulos" class="col-xl-4 col-md-6">
            <article>

              <div class="post-img">
                <img src="{{ articulo.multimedia[0].src }}" alt="{{ articulo.multimedia[0].id }}" class="img-fluid">
              </div>

              <p class="post-category">
                
                <span class="badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill"><a *ngFor="let keyword of articulo.keywords" [routerLink]="['/info/articulos-lista', keyword]" class="p-2">{{ keyword }}</a></span>
              </p>

              <h2 class="title">
                <a [routerLink]="['/info/articulo', articulo.id]">{{ articulo.titulo }}</a>
              </h2>

              <div class="d-flex align-items-center">
                <div class="post-meta">
                  <p>{{ articulo.subtitulo }}</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">{{ toDateTime(articulo.timestamp.seconds) | date: 'MMM d, y' }}</time>
                  </p>
                </div>
              </div>

            </article>
          </div><!-- End post list item -->

        </div><!-- End blog posts list -->
<!-- 
        <div class="blog-pagination">
          <ul class="justify-content-center">
            <li><a href="#">1</a></li>
            <li class="active"><a href="#">2</a></li>
            <li><a href="#">3</a></li>
          </ul>
        </div> -->

      </div>
    </section><!-- End Blog Section -->

  </main><!-- End #main -->
