import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartidosService } from 'src/services/servicio.partidos';
import { RecintosService } from 'src/services/servicio.recintos';

@Component({
  selector: 'app-partido-lista',
  templateUrl: './partido-lista.component.html',
  styleUrls: ['./partido-lista.component.css']
})
export class PartidoListaComponent implements OnInit {
  columnasPartidos: string[] = ['nombrePartido'];
  resultados: any;
  recintos: Array<any>;
  cantidadColumnas: number;
  largoPagina: number;
  pagina: number;
  filtro: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public partidosService: PartidosService,
    private recintosService: RecintosService
  ) { }

  ngOnInit(): void {
    this.largoPagina = 10;  
    this.pagina = 1;
    this.filtro = "";
    this.cantidadColumnas = (window.innerWidth > 600) ? (window.innerWidth > 1200) ? 2 : 2 : 1;
    this.route.params.subscribe(params => {
      var pagina = params['pagina'];
      this.reload();
    });
  }

  reload() : void {
    this.partidosService.getPartidos(this.filtro, this.pagina, this.largoPagina).subscribe(res => {
      this.resultados = res;
      console.log(this.resultados);
    });
  }

  onResize(event) : void {
    console.log(event.target.innerWidth);
    this.cantidadColumnas = (event.target.innerWidth > 600) ? (event.target.innerWidth > 1200) ? 3 : 2 : 1;
  }

  paginadorListener(eventoPaginador: any) : void {
    console.log(eventoPaginador);
    eventoPaginador.previousPageIndex
    this.pagina = eventoPaginador.pageIndex + 1;
    this.largoPagina = eventoPaginador.pageSize;
    this.reload();
  }
  
  buscar() : void {
    this.pagina = 1;
    this.reload();    
  }
  
  public anterior(): void {
    if (this.pagina > 1)
    {
      this.pagina = this.pagina - 1;
      this.reload();
    }
  }

  public siguiente(): void {
    if (this.pagina > 0)
    {
      this.pagina = this.pagina + 1;
      this.reload();
    }
  }
}
