<div class="container">
    <main id="main">
        <ng-container *ngIf="!this.campeonato">
            <div class="px-4 py-5" id="custom-cards">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.campeonato">
            <div class="pt-4">
                <h2 class="pb-4">{{ this.campeonato.nombre }}
                    <button *ngIf="this.esJugadorAdministrador()"
                        [routerLink]="['/campeonato/editor/', 'modificar', this.campeonato.id]"
                        class="btn btn-sm btn-warning" style="float: right;">Modificar</button>
                </h2>


                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active': this.tabSeleccionado == 'info' }"
                            (click)="this.tabSeleccionado = 'info'">Información</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active': this.tabSeleccionado == 'equipos' }"
                            (click)="this.tabSeleccionado = 'equipos'">Equipos</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active': this.tabSeleccionado == 'fixture' }"
                            (click)="this.tabSeleccionado = 'fixture'">Fixture</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active': this.tabSeleccionado == 'partidos' }"
                            (click)="this.tabSeleccionado = 'partidos'">Partidos</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active': this.tabSeleccionado == 'resultados' }"
                            (click)="this.tabSeleccionado = 'resultados'; this.cargarResultados()">Resultados</a>
                    </li>
                </ul>

                <div class="row" *ngIf="this.tabSeleccionado == 'info'">
                    <div class="col-sm-12 col-md-6 col-lg-3 p-4 alias">
                        <span class="small">insignia</span><br>
                        <img *ngIf="this.campeonato.urlLogo" width="100px" src="{{ this.campeonato.urlLogo }}">
                        <ng-container *ngIf="this.esJugadorAdministrador()">
                            <br>
                            <a class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                data-bs-target="#subirInsigniaModal">cambiar</a>
                            <!-- Modal -->
                            <div class="modal fade" id="subirInsigniaModal" tabindex="-1"
                                aria-labelledby="subirInsigniaModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="subirInsigniaModalLabel">Subir
                                                insignia
                                            </h1>
                                            <button type="button" class="btn-close" #cerrarModalSubirInsignia
                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row g-5 p-2">
                                                <app-subir-fichero
                                                    [ubicacionEnBucket]="'campeonatos/' + this.campeonato.id + '/insignia'"
                                                    (ficheroSubido)="logoSubidoListener($event)"></app-subir-fichero>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"
                                                data-bs-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 p-4 fInscripcion">
                        <span class="small">Fechas</span><br>
                        <i class="fa fa-calendar"></i> Inicio: {{ this.campeonato.fechaInicio | date:'MMM d,
                        y'}}
                        <br>
                        <i class="fa fa-calendar"></i> Fin: {{ this.campeonato.fechaFin | date:'MMM d, y'}}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 p-4 ubicacion">
                        <span class="small">ubicación</span><br>
                        <i class="fa fa-location-pin"></i> {{
                        obtenerUbicacion(campeonato.ubicacion.comuna).nombre
                        }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 p-4 fInscripcion">
                        <span class="small">Tipo</span><br>
                        <p *ngIf="this.campeonato.tipo == 0">Desconocido</p>
                        <p *ngIf="this.campeonato.tipo == 1">Liga</p>
                        <p *ngIf="this.campeonato.tipo == 2">Copa</p>
                        <p *ngIf="this.campeonato.tipo == 3">Mixto (Liga y Copa)</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 p-4 alias">
                        <span class="small">descripcion</span><br>
                        {{ this.campeonato.descripcion }}
                        <br><br>
                        <span class="small">inscripciones</span><br>
                        <div [innerHtml]="this.campeonato.detalleInscripcion"></div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 p-4 alias">
                        <span class="small">premios</span><br>
                        <div [innerHtml]="this.campeonato.detallePremios"></div>
                    </div>
                </div>
                <div class="row" *ngIf="this.tabSeleccionado == 'equipos'">
                    <div class="col-sm-12 col-lg-12  p-4">
                        <div class="row" *ngIf="this.campeonato.equiposInscritos">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Color</th>
                                            <th>Ubicación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let inscripcion of this.campeonato.equiposInscritos">
                                            <td><a [routerLink]="['/equipo/', inscripcion.equipo.id]">{{
                                                    inscripcion.equipo.nombre }}</a></td>
                                            <td>
                                                <span class="border border-secondary rounded" style="background-color: {{ inscripcion.equipo.color }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;
                                                <span class="border border-secondary rounded" style="background-color: {{ inscripcion.equipo.colorAlternativo }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </td>
                                            <td>{{ obtenerUbicacion(inscripcion.equipo.ubicacion.comuna).nombre }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.tabSeleccionado == 'fixture'">
                    <p>En construcción</p>
                </div>
                <div class="row" *ngIf="this.tabSeleccionado == 'partidos'">
                    <div class="col-sm-12 col-lg-12  pt-4">
                        <div class="row" *ngIf="!this.ultimosPartidos">
                            <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row" *ngIf="this.ultimosPartidos">
                            <div class="col-md-6 col-sm-12" *ngFor="let partido of this.ultimosPartidos">
                                <div class="shadow-sm p-3 mb-5 bg-white rounded">
                                    <app-partido-miniatura [partido]="partido"></app-partido-miniatura>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.tabSeleccionado == 'resultados'">
                    <div class="p-4">
                        <h4>Tabla de posiciones</h4>
                        <div class="row" *ngIf="!this.resultadosCampeonato">
                            <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <table class="table table-striped" *ngIf="this.resultadosCampeonato">
                            <thead>
                                <tr>
                                    <th>Equipo</th>
                                    <th>PJ</th>
                                    <th>PG</th>
                                    <th>PE</th>
                                    <th>PP</th>
                                    <th>GF</th>
                                    <th>GC</th>
                                    <th>DG</th>
                                    <th>Puntos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let posicion of this.resultadosCampeonato">
                                    <td><a [routerLink]="['/equipo/', posicion.idEquipo]">{{ posicion.nombreEquipo }}</a></td>
                                    <td>{{ posicion.partidosJugados }}</td>
                                    <td>{{ posicion.partidosGanados }}</td>
                                    <td>{{ posicion.partidosEmpatados }}</td>
                                    <td>{{ posicion.partidosPerdidos }}</td>
                                    <td>{{ posicion.golesFavor }}</td>
                                    <td>{{ posicion.golesContra }}</td>
                                    <td>{{ posicion.diferenciaGoles }}</td>
                                    <td>{{ posicion.puntos }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </main>
</div>