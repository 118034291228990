import { Component, Input, OnInit } from '@angular/core';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';

@Component({
  selector: 'app-partido-miniatura',
  templateUrl: './partido-miniatura.component.html',
  styleUrls: ['./partido-miniatura.component.css']
})
export class PartidoMiniaturaComponent implements OnInit {

  @Input()
  public partido: any;

  @Input()
  public esconderVerMas: boolean;

  constructor(private servicioEnumeraciones: EnumeracionesService) { }

  ngOnInit(): void {
  }

  obtenerEstadoPartido() : string {
    return this.servicioEnumeraciones.obtenerEstadoPartido(this.partido.estado);
  }

  estaFinalizado() : boolean {
    return this.partido.estado > 2;
  }
}
