import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class InvitacionesService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersAnonimos() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getInvitaciones(idParticipante: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/invitacion/GetPorParticipante/${idParticipante}`,  { keywords: [idParticipante], largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getInvitacion(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/invitacion/${id}`, options);
    }

    public responderInvitacion(id: string, respuesta: number) : Observable<any> {
        var options =  {
            headers: this.headersAnonimos()
        };
        return this.http.post(`${this.urlApi}/invitacion/Responder/${id}/${respuesta}`, options);
    }
}