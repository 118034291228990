import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { PartidosService } from 'src/services/servicio.partidos';
import { SesionService } from 'src/services/servicio.sesion';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';
import { UtilidadesService } from 'src/services/servicio.utilidades';

@Component({
  selector: 'app-equipo-detalle',
  templateUrl: './equipo-detalle.component.html',
  styleUrls: ['./equipo-detalle.component.css']
})
export class EquipoDetalleComponent implements OnInit {
  public equipoId: any;
  public equipo: any;
  public jugadorIdSesion: string;
  public ultimosPartidos: Array<any>;

  @ViewChild('cerrarModalSubirInsignia') cerrarModalSubirInsignia;
  @ViewChild('cerrarModalSubirFoto') cerrarModalSubirFoto;
  @ViewChild('cerrarModalContratacion') cerrarModalContratacion;
  @ViewChild('cerrarModalNuevaContratacion') cerrarModalNuevaContratacion;

  public posicionesPosibles: Array<any>;

  public contratacionTemporal: any;
  public nuevaContratacionTemporal: any;
  public listaNuevaContratacionTemporal: Array<any>;
  public filtroJugadorTemporal: string;

  public ordenPosiciones: Array<number>;
  public fotoPrincipalResultado: any;
  public logoResultado: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public equipoService: EquiposService,
    public sesionService: SesionService,
    public jugadorService: JugadoresService,
    public partidosService: PartidosService,
    public utilidadesService: UtilidadesService,
    private ubicacionesService: UbicacionesService,
    private enumeracionesService: EnumeracionesService
  ) { }

  ngOnInit(): void {
    this.ordenPosiciones = [1,2,3,4,99]
    this.filtroJugadorTemporal = "";
    this.nuevaContratacionTemporal = {
      id: this.utilidadesService.generateUUID(),
      jugador: {},
      numeroCamiseta: 0,
      esCapitan: false,
      posicion: 0,
    };
    this.route.params.subscribe(params => {
      this.posicionesPosibles = this.enumeracionesService.obtenerPosiciones();
      this.equipoId = params['id'];
      this.reload();
    });
  }

  private reload() : void {
    this.equipoService.getEquipo(this.equipoId).subscribe(res => {
      console.log(res);
      this.jugadorIdSesion = this.sesionService.obtenerSesion().usuarioAutenticado.entidades.filter(j => j.tipo == 0)[0].id;
      this.equipo = res.datos;
      this.partidosService.getPartidosPorEquipo(this.equipoId).subscribe(res2 => {
        this.ultimosPartidos = res2.datos;
      }, error => { console.log(error); });
    });
  }

  public obtenerUbicacion(ubicacionId: string) : any {
    return this.ubicacionesService.getUbicacion(ubicacionId);
  }

  public obtenerColor(colorId: string) : any {
    return this.enumeracionesService.getColor(colorId);
  }

  public esJugadorCapitan(equipo: any) : boolean {
    var jugador = this.equipo.nomina.find(j => j.jugador.id == this.jugadorIdSesion && j.esCapitan);
    return jugador ?? false;
  }

  public modificarContratacion(contratacion: any) : void {
    this.contratacionTemporal = JSON.parse(JSON.stringify(contratacion));
  }

  public nuevaContratacion() : void {
    this.nuevaContratacionTemporal = {
      id: this.utilidadesService.generateUUID(),
      jugador: {},
      numeroCamiseta: 0,
      esCapitan: false,
      posicion: 0,
    };
    console.log(this.nuevaContratacion);
  }

  buscarJugador() : void {
    this.jugadorService.getJugadores(this.filtroJugadorTemporal.toLowerCase(), 1, 3).subscribe(res => {
      this.listaNuevaContratacionTemporal = res.items;
      console.log(this.listaNuevaContratacionTemporal);
    });
  }

  public seleccionarJugadorNuevaContratacion(jugador: any) : void {
    this.nuevaContratacionTemporal.jugador = jugador;
    console.log(this.nuevaContratacionTemporal);
  }

  public guardarContratacion() : void {
    this.equipoService.modificarContratacion(this.equipo, this.contratacionTemporal).subscribe(res => {
      if (res.esCorrecto){
        console.log("es correcto", res);
        this.cerrarModalContratacion.nativeElement.click();
        this.reload();
      }
      else {
        console.log("NO es correcto", res);
      }
    });
  }

  public crearContratacion() : void {
    this.equipoService.crearContratacion(this.equipo, this.nuevaContratacionTemporal).subscribe(res => {
      if (res.esCorrecto){
        console.log("es correcto", res);
        this.cerrarModalNuevaContratacion.nativeElement.click();
        this.jugadorService.borrarCache();
        this.reload();
      }
      else {
        console.log("NO es correcto", res);
      }
    });
  }

  public eliminarContratacion(contratacion: any) : void {
    this.equipoService.eliminarContratacion(this.equipo, contratacion).subscribe(res => {
      if (res.esCorrecto){
        console.log("eliminarContratacion es correcto", res);
        this.jugadorService.borrarCache();
        this.reload();
      }
      else {
        console.log("eliminarContratacion NO es correcto", res);
      }
    });
  }
  
  public logoSubidoListener(data: any) {
    if (data.esCorrecto) {
      console.log(data.value);
      this.equipo.urlLogo = data.value.src;
      this.equipoService.actualizar(this.equipo).subscribe(res => {
        if (res.esCorrecto){
          console.log("es correcto", res);
          this.cerrarModalSubirInsignia.nativeElement.click();
          this.reload();
        }
        else {
          console.log("NO es correcto", res);
        }
      }, error => {
        var mensaje = error.mensajes[0];
        this.logoResultado = mensaje;
      });
    }
    else {
      this.logoResultado = data.mensajes[0];
    }
  }

  public fotoPrincipalSubidaListener(data: any) {
    if (data.esCorrecto) {
      this.equipo.urlEquipo = data.value.src;
      this.equipoService.actualizar(this.equipo).subscribe(res => {
        if (res.esCorrecto){
          console.log("es correcto", res);
          this.cerrarModalSubirFoto.nativeElement.click();
          this.reload();
        }
        else {
          this.fotoPrincipalResultado = res.mensajes[0];
          console.log("NO es correcto", res);
        }
      }, error => {
        var mensaje = error.mensajes[0];
        this.fotoPrincipalResultado = mensaje;
      });
    }
    else {
      this.fotoPrincipalResultado = data.mensajes[0];
    }
   
  }

  public calcularEdad(birthdayString) : number { // birthday is a date
    let birthday = new Date(birthdayString);
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public obtenerJugadoresPorPosicion(jugadores: any, posicion: number) : Array<any> {
    let matches = jugadores.filter(j => j.posicion == posicion);
    return matches;
  }
}
