import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartidosService } from '../../../services/servicio.partidos';

@Component({
  selector: 'app-partido-estadisticas',
  templateUrl: './partido-estadisticas.component.html',
  styleUrls: ['./partido-estadisticas.component.css']
})
export class PartidoEstadisticasComponent implements OnInit {

  public partido: any;
  public estadisticasPartido: any;
  public estadisticasJugadores: any;

  constructor(
    private partidoService: PartidosService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.partidoService.getPartido(id).subscribe(res => {
        if (res) {
          this.partido = res.datos;
          this.partidoService.getEstadisticasPartido(id).subscribe(res2 => { 
            this.estadisticasPartido = res2.datos;
            this.partidoService.getEstadisticasJugadores(id).subscribe(res3 => { 
              this.estadisticasJugadores = res3.datos;

              console.log(this.partido);
              console.log(this.estadisticasJugadores);
              console.log(this.estadisticasPartido);

            });
          });
        }
      })
    });
  }

}
