<main id="main" *ngIf="this.articulo">

    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
        <div class="page-header d-flex align-items-center" style="background-image: url('');">
            <div class="container position-relative">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6 text-center">
                        <h2>{{ articulo.titulo }}</h2>
                        <p>{{ articulo.subtitulo }}</p>
                    </div>
                </div>
            </div>
        </div>
        <nav>
            <div class="container">
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Articulos</li>
                </ol>
            </div>
        </nav>
    </div><!-- End Breadcrumbs -->

    <!-- ======= Blog Details Section ======= -->
    <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">

            <div class="row g-5">

                <div class="col-lg-8">

                    <article class="blog-details">
                        <div *ngIf="this.imagenPrincipal" class="post-img">
                            <img src="{{ this.imagenPrincipal.src }}" alt="{{ this.imagenPrincipal.id }}" class="img-fluid">
                        </div>

                        <h2 class="title">{{ this.articulo.titulo }}</h2>

                        <div class="meta-top">
                            <ul>
                                <li class="d-flex align-items-center"><i class="bi bi-person"></i> {{ articulo.autor }}</li>
                                <li class="d-flex align-items-center"><i class="bi bi-clock"></i> {{ toDateTime(articulo.timestamp.seconds) | date: 'MMM d, y' }}</li>
                            </ul>
                        </div><!-- End meta top -->


                        <ng-container *ngIf="this.videoPrincipal" >
                            <iframe width='560' height='315' [src]='this.videoPrincipal.src' frameborder='0' allowfullscreen></iframe>
                        </ng-container>


                        <div class="content" [innerHtml]="this.articulo.contenido">
                        
                        </div><!-- End post content -->

                        <div class="meta-bottom">
                            <i class="fa-solid fa-tags p-2"></i>
                            <ul class="tags">
                                <li *ngFor="let key of this.articulo.keywords"><a [routerLink]="['/info/articulos-lista', key]" >{{key}}</a></li>
                            </ul>
                        </div><!-- End meta bottom -->

                    </article><!-- End blog post -->
                </div>

                <div class="col-lg-4">

                    <div class="sidebar">
                        <div class="sidebar-item categories">
                            <h3 class="sidebar-title">Categorias</h3>
                            <ul class="mt-3">
                                <li><a [routerLink]="['/info/articulos-lista', 'ayuda']">Ayuda</a></li>
                                <li><a [routerLink]="['/info/articulos-lista', 'terminos de uso']">Terminos de uso</a></li>
                                <li><a [routerLink]="['/info/articulos-lista', 'general']">General</a></li>
                            </ul>
                        </div><!-- End sidebar categories-->

                        <div class="sidebar-item tags">
                            <h3 class="sidebar-title">Keywords</h3>
                            <ul class="mt-3">
                                <li *ngFor="let key of this.articulo.keywords"><a [routerLink]="['/info/articulos-lista', key]">{{key}}</a></li>
                            </ul>
                        </div><!-- End sidebar tags-->

                    </div><!-- End Blog Sidebar -->

                </div>
            </div>

        </div>
    </section><!-- End Blog Details Section -->

</main><!-- End #main -->

