import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampeonatosService } from 'src/services/servicio.campeonatos';
import { PartidosService } from 'src/services/servicio.partidos';
import { SesionService } from 'src/services/servicio.sesion';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-campeonato-detalle',
  templateUrl: './campeonato-detalle.component.html',
  styleUrls: ['./campeonato-detalle.component.css']
})
export class CampeonatoDetalleComponent implements OnInit {
  campeonatoId: string;
  campeonato: any;
  jugadorIdSesion: string;

  tabSeleccionado: string;

  ultimosPartidos: Array<any>;

  resultadosCampeonato: any;

  @ViewChild('cerrarModalSubirInsignia') cerrarModalSubirInsignia;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private campeonatoService: CampeonatosService,
    private partidosService: PartidosService,
    private sesionService: SesionService,
    private ubicacionesService: UbicacionesService) { }

  ngOnInit(): void {
    this.tabSeleccionado = 'info';
    this.route.params.subscribe(params => {
      this.campeonatoId = params['id'];
      this.reload();
    });
  }

  private reload() : void {
    this.campeonatoService.getCampeonato(this.campeonatoId).subscribe(res => {
      console.log(res);
      this.campeonato = res.datos;
      this.cargarPartidos();
      this.jugadorIdSesion = this.sesionService.obtenerSesion().usuarioAutenticado.entidades.filter(j => j.tipo == 0)[0].id;
    });
  }

  public esJugadorAdministrador() : boolean {
    var jugador = this.campeonato.administradores.find(j => j.participanteId == this.jugadorIdSesion);
    return jugador ?? false;
  }

  public obtenerUbicacion(ubicacionId: string) : any {
    return this.ubicacionesService.getUbicacion(ubicacionId);
  }

  private cargarPartidos() : void {
    this.partidosService.getMany(this.campeonato.planificacion.partidosFaseRegular).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.ultimosPartidos = res.datos;
      }
    });
  }

  public cargarResultados() : void {
    this.campeonatoService.getResultadosLiga(this.campeonato.id).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.resultadosCampeonato = res.datos.tablaPosiciones;
      }
    });
  }

  public logoSubidoListener(data: any) {
    console.log(data.value);
    this.campeonato.urlLogo = data.value.src;
    this.campeonatoService.actualizar(this.campeonato).subscribe(res => {
      if (res.esCorrecto){
        console.log("es correcto", res);
        this.cerrarModalSubirInsignia.nativeElement.click();
        this.reload();
      }
      else {
        console.log("NO es correcto", res);
      }
    });
  }

}
