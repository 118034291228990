import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable, Observer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class JugadoresService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }


    public jugadorAutenticado() : Observable<any> {
        let id = this.servicioSesiones.obtenerSesion().usuarioAutenticado.entidades.filter(i => i.tipo == 0)[0].id;
        //implementar un cache aqui
        var jugadorEnCache = this.obtenerJugadorEnCache();
        if (jugadorEnCache) {
            console.log("usando jugador del cache: ", jugadorEnCache);
            return new Observable((observer: Observer<object>) => {
                observer.next(jugadorEnCache);
            }); 
        }
        else {
            return new Observable((observer: Observer<object>) => {
                console.log("obteniendo jugador de la API");
                this.getJugador(id).subscribe(res => {
                    this.setJugadorEnCache(res);
                    observer.next(res);
                    console.log(res);
                });
            }); 
        }
    }

    public getJugadores(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/jugador/Buscar`, { keywords: filtro.split(' '), largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getJugador(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/jugador/${id}`, options);
    }

    public getMany(ids: Array<string>) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        let url = `${this.urlApi}/jugador/getmany/?ids=${ids.join(",")}`;
        console.log(url);
        return this.http.get(url, options);
    }

    public crear(jugador: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.put(`${this.urlApi}/jugador`, jugador, options);
    }

    public actualizar(jugador: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        this.borrarCache();
        return this.http.post(`${this.urlApi}/jugador`, jugador, options);
    }

    private setJugadorEnCache(jugador: any){
        localStorage.setItem('jugadorSesionTocala', JSON.stringify(jugador));
    }

    private obtenerJugadorEnCache() : any {
        return JSON.parse(localStorage.getItem('jugadorSesionTocala'));
    }

    public borrarCache() : void {
        localStorage.removeItem('jugadorSesionTocala');
    }
}