import { Component, Input, OnInit } from '@angular/core';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-equipo-miniatura',
  templateUrl: './equipo-miniatura.component.html',
  styleUrls: ['./equipo-miniatura.component.css']
})
export class EquipoMiniaturaComponent implements OnInit {

  @Input()
  public equipo: any;

  constructor(private ubicacionesService: UbicacionesService) { }

  ngOnInit(): void {
  }

  
  public obtenerUbicacion(ubicacionId: string) : any {
    return this.ubicacionesService.getUbicacion(ubicacionId);
  }

}
