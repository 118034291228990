<div class="container">
    <main id="main">

        <div class="px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom"><span>Jugadores</span></h2>

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" name="Buscar" placeholder="'Marcelo', 'Ivan', etc"
                            [(ngModel)]="this.filtro" required="">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.buscar()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row" *ngIf="this.resultados">
                <div *ngFor="let jugador of this.resultados.items" class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="shadow-sm p-3 mb-5 bg-white rounded">
                        <span>
                            <app-posicion-miniatura [posicion]="jugador.posicion"></app-posicion-miniatura>
                        </span>&nbsp;
                        <span class="nombreJugador">{{ jugador.nombre }} {{ jugador.apellido }}</span><br>
                        <span class="aliasJugador">{{ jugador.alias }}</span><br>
                        <span><button class="btn btn-sm btn-primary" [routerLink]="['/jugador', jugador.id]">Ver
                                más</button></span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!this.resultados">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
            </div>


            <div class="row">
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" (click)="this.anterior()" ><a class="paginadorBtn" >Anterior</a></li>
                            <li><span style="font-size: 9pt; padding: 5px 20px">Pagina {{ this.pagina }}</span></li>
                            <li class="page-item" (click)="this.siguiente()"><a class="paginadorBtn" >Siguiente</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </main>
</div>