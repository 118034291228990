<div class="container px-4 py-5">
    <main id="main">

        <div class="row g-5">
            <div class="col-md-12 col-lg-12">
                <h2 class="pb-2 border-bottom">
                    <span *ngIf="this.accion == 'crear'">Crear</span>
                    <span *ngIf="this.accion == 'modificar'">Modificar</span> Recinto
                </h2>
                <div class="row g-3" *ngIf="this.recinto">

                    <div class="col-sm-12">
                        <label for="Nombre" class="form-label">Nombre</label>
                        <input class="form-control" name="Nombre" required="" [(ngModel)]="this.recinto.nombre" />
                        <div class="invalid-feedback">
                            Un nombre válido es requerido.
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <label for="Descripcion" class="form-label">Descripción</label>
                        <textarea class="form-control" name="Descripcion" required="" [(ngModel)]="this.recinto.descripcion"></textarea>
                        <div class="invalid-feedback">
                            Una descripción válida es requerida.
                        </div>
                    </div>


                    <div class="col-sm-12">
                        <label for="Direccion" class="form-label">Direccion</label>
                        <input class="form-control" name="Direccion" required="" [(ngModel)]="this.recinto.ubicacion.direccion" />
                        <div class="invalid-feedback">
                            Una descripción válida es requerida.
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label for="Comuna" class="form-label">Comuna</label>
                        <select class="form-select" name="Ubicacion" required="" [(ngModel)]="this.recinto.ubicacion.comuna">
                          <option *ngFor="let ubicacion of this.ubicacionesPosibles" [ngValue]="ubicacion.key">{{ubicacion.value}}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Por favor seleccione alguna ubicación válida
                        </div>
                      </div>

                    <div class="col-sm-12">
                        <label for="Latitud" class="form-label">Latitud</label>
                        <input class="form-control" name="Latitud" required="" [(ngModel)]="this.recinto.ubicacion.latitud" />
                        <div class="invalid-feedback">
                            Una Latitud válida es requerida.
                        </div>
                    </div>


                    <div class="col-sm-12">
                        <label for="Longitud" class="form-label">Longitud</label>
                        <input class="form-control" name="Longitud" required="" [(ngModel)]="this.recinto.ubicacion.longitud" />
                        <div class="invalid-feedback">
                            Una Longitud válida es requerida.
                        </div>
                    </div>

                    <button class="w-100 btn btn-warning btn-lg" (click)="guardar()">Guardar</button>
                </div>
            </div>
        </div>
    </main>
</div>