import { Injectable } from '@angular/core';

@Injectable()
export class EnumeracionesService {

  public getColor(colorId: string): any {
    return this.colores.find(c => c.id == colorId);
  }

  public getColores(): Array<any> {
    return this.colores;
  }

  private colores: Array<any> =
    [
      {
        "id": "1",
        "nombre": "Celeste",
        "rgb": "#5555FF"
      },
      {
        "id": "2",
        "nombre": "Azul",
        "rgb": "#0000FF"
      },
      {
        "id": "3",
        "nombre": "Amarillo",
        "rgb": "#FFFF00"
      }
    ];

  public obtenerPosicion(posicion: number): string {
    switch (posicion) {
      
      case 1:
        return "Arquero";
      case 2:
        return "Defensa";
      case 3:
        return "Mediocampo";
      case 4:
        return "Atacante";
      case 99:
        return "Parche";
      default:
        return "Desconocido";
    }
  }

  public obtenerEstadoPartido(estadoPartido: number): string {
    switch (estadoPartido) {
      case 1:
        return "Pendiente";
      case 2:
        return "Confirmado";
      case 3:
        return "Finalizado";
      case 4:
        return "Cancelado";
      case 5:
        return "Postergado";
      default:
          return "Desconocido";
    }
  }

  public obtenerPosiciones(): Array<string> {
    var posiciones = new Array<any>();
    posiciones.push({ key: 0, value: "Desconocido" });
    posiciones.push({ key: 1, value: "Arquero" });
    posiciones.push({ key: 2, value: "Defensa" });
    posiciones.push({ key: 3, value: "Mediocampo" });
    posiciones.push({ key: 4, value: "Atacante" });
    posiciones.push({ key: 99, value: "Parche" });
    return posiciones;
  }

  public obtenerSuperficies(): Array<string> {
    var superficies = new Array<any>();
    superficies.push({ key: 0, value: "Desconocido" });
    superficies.push({ key: 1, value: "Pasto Natural" });
    superficies.push({ key: 2, value: "Pasto Sintético" });
    superficies.push({ key: 3, value: "Carpeta" });
    superficies.push({ key: 4, value: "Parquet" });
    superficies.push({ key: 5, value: "Cemento" });
    superficies.push({ key: 6, value: "Tierra" });
    superficies.push({ key: 7, value: "Arena" });
    return superficies;
  }

  public obtenerOpcionesCantidadJugadores(): Array<Number> {
    var opciones = new Array<any>();
    opciones.push(1);
    opciones.push(2);
    opciones.push(3);
    opciones.push(4);
    opciones.push(5);
    opciones.push(6);
    opciones.push(7);
    opciones.push(8);
    opciones.push(9);
    opciones.push(10);
    opciones.push(11);
    return opciones;
  }

  public obtenerAcciones() : Array<any> {
    var acciones = new Array<any>();
    acciones.push({ key: 0, value: "Indefinida" });
    acciones.push({ key: 1, value: "Gol" });
    acciones.push({ key: 2, value: "Auto Gol" });
    acciones.push({ key: 3, value: "Tiro a Porteria" });
    acciones.push({ key: 4, value: "Pase" });
    acciones.push({ key: 5, value: "Quite" });
    acciones.push({ key: 6, value: "Perdida" });
    acciones.push({ key: 7, value: "Inicia Jugada" });
    acciones.push({ key: 20, value: "T. Amarilla" });
    acciones.push({ key: 21, value: "T. Roja" });
    return acciones;
  }

  public obtenerAccion(accionPartido: number) : string {
    
    switch (accionPartido) {
      case 1:
        return "Gol";
      case 2:
        return "Auto Gol";
      case 3:
        return "Tiro a Porteria";
      case 4:
        return "Pase";
      case 5:
        return "Quite";
      case 6:
        return "Perdida";
      case 7:
        return "Inicia Jugada";
      case 20:
        return "Amarilla";
      case 21:
        return "Roja";
      default:
        return "Indefinida";
    }
  }

}