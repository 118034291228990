import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PartidoDetalleComponent } from './partido-detalle/partido-detalle.component';
import { PartidosService } from '../../services/servicio.partidos';
import { PartidoEstadisticasComponent } from './partido-estadisticas/partido-estadisticas.component';
import { RouterModule } from '@angular/router';
import { PartidoListaComponent } from './partido-lista/partido-lista.component';
import { RecintosService } from 'src/services/servicio.recintos';
import { SharedModule } from '../shared/shared.module';
import { PartidoEditorComponent } from './partido-editor/partido-editor.component';
import { PartidoVeedorComponent } from './partido-veedor/partido-veedor.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    FormsModule
  ],
  providers: [PartidosService, RecintosService, DatePipe],
  declarations: [PartidoDetalleComponent, PartidoEstadisticasComponent, PartidoListaComponent, PartidoEditorComponent, PartidoVeedorComponent]
})
export class PartidosModule { }
