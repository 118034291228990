import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/services/servicio.sesion';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  sesion: any;
  constructor(
    private router: Router,
    private sesionService: SesionService) { }

  @ViewChild('menuPrincipalButton') cerrarMenuElement;

  ngOnInit(): void {
  }

  estaActiva(): boolean {
    return this.sesionService.estaSesionActiva();
  }

  salir(): void {
    this.sesionService.salir();
    this.cerrarMenu();
  }

  cerrarMenu() : void {
    console.log(this.cerrarMenuElement);
    this.cerrarMenuElement.nativeElement.click();
    console.log("cerrando menu");
  }
}
