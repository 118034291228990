import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-jugador-editor',
  templateUrl: './jugador-editor.component.html',
  styleUrls: ['./jugador-editor.component.css']
})

export class JugadorEditorComponent implements OnInit {
  public jugador: any;
  public posicionesPosibles: Array<any>;
  public ubicacionesPosibles: Array<any>;
  
  public fechaNacSeleccionada: any;

  public respuestaPoliticaPrivacidad: boolean;
  
  public mensajesError: Array<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jugadoresService: JugadoresService,
    private ubicacionesService: UbicacionesService,
    private enumeracionesService: EnumeracionesService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.ubicacionesPosibles = this.ubicacionesService.obtenerComunas();
      this.posicionesPosibles = this.enumeracionesService.obtenerPosiciones();
      console.log(this.ubicacionesPosibles);
      var accion = params['accion'];
      var id = params['id'];
      this.jugadoresService.getJugador(id).subscribe(res => {
        this.jugador = res.datos;
        this.fechaNacSeleccionada = this.datePipe.transform(this.jugador.fechaNacimiento,'yyyy-MM-dd');
      });
    });
  }

  guardar() : void {
    let dia = this.fechaNacSeleccionada.split('-')[2]*1;
    let mes = this.fechaNacSeleccionada.split('-')[1]*1;
    let anio = this.fechaNacSeleccionada.split('-')[0]*1;

    let horario = new Date(anio, mes - 1, dia, 0, 0, 0, 0);
    let horarioDate = this.datePipe.transform(horario, 'yyyy-MM-ddTHH:mm:ss');
    this.jugador.fechaNacimiento = new Date(Date.parse(horarioDate));

    console.log(this.jugador);
    if (this.validar()) {
      this.jugadoresService.actualizar(this.jugador).subscribe(res => {
        this.jugador = res.datos;
        console.log(res);
        this.router.navigate(['mi-perfil']);
      });
    }
  }

  validar() : boolean {
    this.mensajesError = [];

    if (this.jugador.nombre == ""){
      this.mensajesError.push("Ingrese un nombre válido");
    }

    if (this.jugador.apellido == ""){
      this.mensajesError.push("Ingrese un apellido válido");
    }

    if (this.jugador.alias == ""){
      this.mensajesError.push("Ingrese un alias válido");
    }
    
    if (this.mensajesError.length > 0){
      return false;
    }

    return true;
    
  }
  
  fechaNacCambiada(event: any): void {
    this.fechaNacSeleccionada = event.target.value;
  }
}
