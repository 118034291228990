<ng-container *ngIf="accion == 1">
    <img src="/assets/img/footballIcons/goal_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 2">
    <img src="/assets/img/footballIcons/goal_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 3"> 
    <img src="/assets/img/footballIcons/commentary_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 4">
    <img src="/assets/img/footballIcons/commentary_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 5">
    <img src="/assets/img/footballIcons/commentary_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 6">
    <img src="/assets/img/footballIcons/commentary_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 7">
    <img src="/assets/img/footballIcons/commentary_icon.svg">
</ng-container>

<ng-container *ngIf="accion == 20">
    <img src="/assets/img/footballIcons/yellow_card_icon.svg"> 
</ng-container>
 
<ng-container *ngIf="accion == 21">
    <img src="/assets/img/footballIcons/red_card_icon.svg">
</ng-container>

{{ this.obtenerAccionPartido() }}