<div class="container" style="background-color: white">
  <main id="main">
    <br>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <div class="wrap d-md-flex">
          <div class="img" style="background-image: url(/assets/img/panel-backgrounds/back_alemania.png);">
          </div>
          <div *ngIf="this.panelIngreso" class="login-wrap p-4 p-md-5">
            <div *ngIf="error" class="card text-bg-danger">
              <div class="card-body">
                {{ error }}
              </div>
            </div>
            <div *ngIf="notificacion" class="card text-bg-success">
              <div class="card-body">
                {{ notificacion }}
              </div>
            </div>
            <div class="d-flex">
              <div class="w-100">
                <h3 class="mb-4">Ingreso</h3>
              </div>
              <div class="w-100">
                <p class="social-media d-flex justify-content-end">
                  <a href="https://www.facebook.com/profile.php?id=100092621465273" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-facebook"></i></a>
                  <a href="https://twitter.com/tocala_cl" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-twitter"></i></a>
                  <a href="https://www.instagram.com/tocalachile/" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-instagram"></i></a>
                </p>
              </div>
            </div>
            <form action="#" class="signin-form">
              <div class="form-group mb-3">
                <label class="label" for="Email">Email</label>
                <input type="text" [(ngModel)]="this.usuario.Email" style="text-transform: lowercase" name="Email" class="form-control" placeholder="Email"
                  required />
              </div>
              <div class="form-group mb-3">
                <label class="label" for="Password">Password</label>
                <input type="password" [(ngModel)]="this.usuario.Password" name="Password" class="form-control"
                  placeholder="*********" required>
              </div>
              <div class="form-group">
                <button type="submit" (click)="ingresar()" [disabled]="this.estaAutenticando"
                  class="form-control btn btn-primary rounded submit px-3">
                  <div *ngIf="this.estaAutenticando" class="spinner-border text-warning" role="status">
                    <span class="sr-only"></span>
                  </div>
                  <span *ngIf="!this.estaAutenticando">Ingresar</span>
                </button>
              </div>
              <div class="form-group d-md-flex">
                <!-- <div class="w-50 text-left">
                  <label class="checkbox-wrap checkbox-primary mb-0">Recuérdame
                    <input type="checkbox" checked="">
                    <span class="checkmark"></span>
                  </label>
                </div> -->
                <div class="w-100 text-md-right">
                  <a class="text-primary" href="javascript:;" (click)="olvidoContrasena()">¿Olvidó su contraseña?</a>
                </div>
              </div>
            </form>
            <p class="text-center">Aun no es miembro? &nbsp; <a [routerLink]="['/usuario/registro']"
                class="text-primary text-small">Cree su cuenta</a></p>
          </div>

          <div *ngIf="!this.panelIngreso" class="login-wrap p-4 p-md-5">
            <div class="d-flex">
              <div class="w-100">
                <h3 class="mb-4">Recordar Contraseña</h3>
              </div>
              <div class="w-100">
                <p class="social-media d-flex justify-content-end">
                  <a href="https://www.facebook.com/profile.php?id=100092621465273" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-facebook"></i></a>
                  <a href="https://twitter.com/tocala_cl" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-twitter"></i></a>
                  <a href="https://www.instagram.com/tocalachile/" target="_blank" class="social-icon d-flex align-items-center justify-content-center"><i class="fa-brands fa-instagram"></i></a>
                </p>
              </div>
            </div>
            <form action="#" class="signin-form">
              <p>Ingrese su correo electrónico para enviarle una contraseña temporal.</p>

              <div class="form-group mb-3">
                <label class="label" for="Email">Email</label>
                <input type="text" [(ngModel)]="this.usuario.Email" name="Email" class="form-control" style="text-transform: lowercase" placeholder="Email"
                  required />
              </div>
              <div class="form-group">
                <button type="submit" (click)="reenviarContrasena()" [disabled]="this.estaRecordando"
                  class="form-control btn btn-primary rounded submit px-3">
                  <div *ngIf="this.estaRecordando" class="spinner-border text-warning" role="status">
                    <span class="sr-only"></span>
                  </div>
                  <span *ngIf="!this.estaRecordando">Reenviar nueva contraseña</span></button>
              </div>
              <div class="form-group d-md-flex">
                <div class="w-50 text-md-right">
                  <a href="javascript:;" (click)="volverAlIngreso()" class="text-small forgot-password text-primary">Ir al
                    Ingreso.</a>
                </div>
              </div>
            </form>
            <p class="text-center">Aun no es miembro? &nbsp; <a [routerLink]="['/usuario/registro']"
                class="text-primary text-small">Cree su cuenta</a></p>
          </div>
        </div>
      </div>
    </div>
    <br>
  </main>
</div>