import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultimediaService } from 'src/services/servicio.multimedia';

@Component({
  selector: 'app-subir-fichero',
  templateUrl: './subir-fichero.component.html',
  styleUrls: ['./subir-fichero.component.css']
})
export class SubirFicheroComponent implements OnInit {
  fichero: any;

  @Input() ubicacionEnBucket = "";

  @Output() ficheroSubido = new EventEmitter();

  constructor(private servicioMultimedia: MultimediaService) { }

  ngOnInit(): void {
  }

  setearFichero(ficheros: any): void {
    this.fichero = ficheros.target.files[0];
  }

  subir(): void {
    this.servicioMultimedia.crear(this.ubicacionEnBucket, this.fichero).subscribe(
      res => {
        if (res.esCorrecto){
          this.ficheroSubido.emit({ esCorrecto: true, value: res.datos });
        }
        else {
          this.ficheroSubido.emit({ esCorrecto: false, mensajes: res.mensajes });
        }
      },
      error => {
        this.ficheroSubido.emit({ esCorrecto: false, mensajes: error.mensajes });
      }
    );
  }
}
