import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PartidosModule } from './partidos/partidos.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JugadoresModule } from './jugadores/jugadores.module';
import { EquiposModule } from './equipos/equipos.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { Router, RouterModule } from '@angular/router';
import { InterceptorAutentificacion } from 'src/services/interceptors/autorization.interceptor';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RecintosModule } from './recintos/recintos.module';
import { InfoModule } from './info/info.module';
import { HomeComponent } from './portales/home/home.component';
import { PortalesModule } from './portales/portales.module';
import { CampeonatosModule } from './campeonatos/campeonatos.module';
import { SesionService } from 'src/services/servicio.sesion';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PortalesModule,
    PartidosModule,
    JugadoresModule,
    EquiposModule,
    UsuariosModule,
    InfoModule,
    RecintosModule,
    CampeonatosModule,
    NoopAnimationsModule
  ],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: authHttpServiceInterceptor,
      multi: true,
      deps: [Router, SesionService],
    },
  ]
})
export class AppModule { }


export function authHttpServiceInterceptor(router: Router, sesionService: SesionService) {
  return new InterceptorAutentificacion(router, sesionService);
}
