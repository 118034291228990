import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioIngresoComponent } from './usuario-ingreso/usuario-ingreso.component';
import { RouterModule } from '@angular/router';
import { UsuarioRegistroComponent } from './usuario-registro/usuario-registro.component';
import { UsuariosService } from 'src/services/servicio.usuarios';
import { FormsModule } from '@angular/forms';
import { SesionService } from 'src/services/servicio.sesion';

@NgModule({
  declarations: [UsuarioIngresoComponent, UsuarioRegistroComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  providers: [UsuariosService, SesionService]
})
export class UsuariosModule { }
