
<ng-container *ngIf="posicion == 1">
    <span style="background-color: #b17900;" data-toggle="tooltip" data-html="true" title="{{ obtenerPosicion(posicion) }}">POR</span>
</ng-container>

<ng-container *ngIf="posicion == 2">
    <span style="background-color: #00a2bb;">DEF</span>
</ng-container>

<ng-container *ngIf="posicion == 3">
    <span style="background-color: #00b628;">MED</span>
</ng-container>

<ng-container *ngIf="posicion == 4">
    <span style="background-color: #ad0000;">DEL</span>
</ng-container>
 