<div class="container px-4 py-5" id="custom-cards">
    <h2 class="pb-2 border-bottom"><span>Recintos</span></h2>
    <div class="row" *ngIf="this.resultados">
        <div *ngFor="let recinto of this.resultados.items" class="col-sm-12 col-md-6 col-lg-4 p-2">
            <div class="shadow-sm p-3 mb-5 bg-white rounded">
                <span class="nombreRecinto">{{ recinto.nombre }}</span><br>
                <span class="ubicacionRecinto">{{ this.obtenerUbicacion(this.recinto.ubicacion.comuna).nombre }}</span><br>
                <span><button class="btn btn-sm btn-primary" [routerLink]="['/recinto', recinto.id]">Ver más</button></span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!this.resultados">
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
    </div>
</div>