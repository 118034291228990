
<div *ngIf="this.partido" class="row">
    <div>
      <span>Capturar Estadisticas Partido</span>
    </div>
    <p>ESTA PAGINA ES LA DEL VEEDOR QUE DEBERIA TENER LA FORMA MAS PULENTA DE CAPTURAR LAS ESTADISTICAS</p>    
    <div>
      <div label="Alineaciones">
        <table style="width: 100%;">
          <tr>
            <td style="vertical-align: top;">
              <table  style="width: 100%;" class="divle cdk-table ng-star-inserted">
                <tr>
                  <th colspan="3" style="text-align: justify;">
                    {{this.partido.local.alias}}
                  </th>
                </tr>
                <tr *ngFor="let invitacion of this.nominaLocal">
                  <td style="text-align: justify">
                    <a [routerLink]="['/privado/jugador', invitacion.jugador.id]"> {{ invitacion.jugador.nombre }} {{ invitacion.jugador.apellido }}</a>
                  </td>
                  <td style="">
                    <button class="btn btn-icons btn-rounded btn-outline-primary" *ngIf="this.estaRegistrando"
                      (click)="registrar(invitacion.jugador, this.partido.local)">
                      <i class="fa fa-futbol" [ngClass]="{'rojo': invitacion.jugador.id === this.tieneLaPosesion.id }"></i>
                    </button>
                  </td>
                  <td style="">
                    <small class="text-muted ml-auto">
                      <span *ngIf="invitacion.jugador.id === this.tieneLaPosesion.id">
                        <button class="btn btn-success" *ngIf="!panelDisparoActivo"
                          (click)="panelDisparoActivo = true">Disparo</button>
                        <button class="btn btn-warning" *ngIf="!panelDisparoActivo"
                          (click)="perdida(invitacion.jugador, this.partido.local)">Pérdida</button>
                        <span *ngIf="panelDisparoActivo">
                          <button class="btn btn-warning" (click)="disparo(invitacion.jugador, this.partido.local)">Al
                            arco</button>
                          <button class="btn btn-success"
                            (click)="gol(invitacion.jugador, this.partido.local)">Gol!</button>
                        </span>
                      </span>
                    </small>
                  </td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: top;">
              <table style="width: 100%;" class="divle cdk-table ng-star-inserted">
                <tr>
                  <th colspan="3" style="text-align: justify;">{{this.partido.visita.alias}}</th>
                </tr>
                <tr *ngFor="let invitacion of this.nominaVisita">
                  <td style="text-align: justify;">
                    <a [routerLink]="['/privado/jugador', invitacion.jugador.id]"> {{ invitacion.jugador.nombre }} {{ invitacion.jugador.apellido }} </a>
                  </td>
                  <td style="width: 30px;">
                    <button class="btn btn-icons btn-rounded btn-outline-primary" *ngIf="this.estaRegistrando"
                      (click)="registrar(invitacion.jugador, this.partido.visita)">
                      <i class="fa fa-futbol" [ngClass]="{'rojo': invitacion.jugador.id === this.tieneLaPosesion.id }"></i>
                    </button>
                  </td>
                  <td>
                    <small class="text-muted ml-auto">
                      <span *ngIf="invitacion.jugador.id === this.tieneLaPosesion.id && this.estaRegistrando">
                        <button class="btn btn-success" *ngIf="!panelDisparoActivo"
                          (click)="panelDisparoActivo = true">Disparo</button>
                        <button class="btn btn-warning" *ngIf="!panelDisparoActivo"
                          (click)="perdida(invitacion.jugador, this.partido.visita)">Pérdida</button>
                        <span *ngIf="panelDisparoActivo">
                          <button class="btn btn-warning" (click)="disparo(invitacion.jugador, this.partido.visita)">Al
                            arco</button>
                          <button class="btn btn-success"
                            (click)="gol(invitacion.jugador, this.partido.visita)">Gol!</button>
                        </span>
                      </span>
                    </small>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div label="Estadisticas">
        <table class="divle cdk-table mat-elevation-z8 ng-star-inserted" width="100%"
          *ngIf="this.estadisticasPartido">
          <tr>
            <td class="score">
              {{ this.estadisticasPartido.marcadorLocal }}
            </td>
            <td>Gol</td>
            <td class="score">
              {{ this.estadisticasPartido.marcadorVisita }}
            </td>
          </tr>
          <tr>
            <th>{{ this.partido.local.nombre }}</th>
            <th></th>
            <th>{{ this.partido.visita.nombre }}</th>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.pasesLocal }}</td>
            <td>Pases</td>
            <td>{{ this.estadisticasPartido.pasesVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.tirosPorteriaLocal }}</td>
            <td>Tiros</td>
            <td>{{ this.estadisticasPartido.tirosPorteriaVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.perdidasLocal }}</td>
            <td>Perdidas</td>
            <td>{{ this.estadisticasPartido.perdidasVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.quitesLocal }}</td>
            <td>Quites</td>
            <td>{{ this.estadisticasPartido.quitesVisita }}</td>
          </tr>
        </table>
      </div>
    </div>