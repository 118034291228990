import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campeonato-editor',
  templateUrl: './campeonato-editor.component.html',
  styleUrls: ['./campeonato-editor.component.css']
})
export class CampeonatoEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
