import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecintosService } from 'src/services/servicio.recintos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-recinto-detalle',
  templateUrl: './recinto-detalle.component.html',
  styleUrls: ['./recinto-detalle.component.css']
})
export class RecintoDetalleComponent implements OnInit {

  recintoId: string;
  recinto: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public recintoService: RecintosService,
    public ubicacionesService: UbicacionesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.recintoId = params['id'];
      this.reload();
    });
  }

  reload() : void {
    this.recintoService.getRecinto(this.recintoId).subscribe(res => {
      if (res.esCorrecto){
        this.recinto = res.datos;
        console.log(this.recinto);
      }
      else{

      }
      
    });
  }

  
  public obtenerUbicacion(ubicacionId: string) : any {
    let ubicacion = this.ubicacionesService.getUbicacion(ubicacionId);
    return ubicacion;
  }
}

