<div class="container">
    <main id="main">
<div class="row">
        <div class="col-xs-12" >
            <h2 *ngIf="this.respuesta == 1">El tecnico estará feliz de verte!</h2>
            <h2 *ngIf="this.respuesta == 2">Que lástima que no puedas ir.</h2>
            <p>Serás redirigido a los detalles del partido en 4 segundos...</p>
        </div>
    </div>
    </main>
</div>