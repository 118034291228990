import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticulosService } from 'src/services/servicio.articulos';

@Component({
  selector: 'app-articulo-lista',
  templateUrl: './articulo-lista.component.html',
  styleUrls: ['./articulo-lista.component.css']
})
export class ArticuloListaComponent implements OnInit {
  keyword: string;
  articulos: Array<any>;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicioArticulos: ArticulosService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.keyword = params['categoria'];
      console.log(this.keyword);
      this.servicioArticulos.getArticulosPorCategoria(this.keyword, 1, 10).subscribe(res => {
        console.log(res);
        this.articulos = res.items;
      });
    });
  }

  public toDateTime(secs) : Date {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }
}
