import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EquipoDetalleComponent } from './equipos/equipo-detalle/equipo-detalle.component';
import { EquipoEditorComponent } from './equipos/equipo-editor/equipo-editor.component';
import { EquipoListaComponent } from './equipos/equipo-lista/equipo-lista.component';
import { ArticuloDetalleComponent } from './info/articulo-detalle/articulo-detalle.component';
import { ArticuloListaComponent } from './info/articulo-lista/articulo-lista.component';
import { IndexComponent } from './info/index/index.component';
import { JugadorDetalleComponent } from './jugadores/jugador-detalle/jugador-detalle.component';
import { JugadorEditorComponent } from './jugadores/jugador-editor/jugador-editor.component';
import { JugadorListaComponent } from './jugadores/jugador-lista/jugador-lista.component';
import { MiPerfilComponent } from './jugadores/mi-perfil/mi-perfil.component';
import { PartidoDetalleComponent } from './partidos/partido-detalle/partido-detalle.component';
import { PartidoEditorComponent } from './partidos/partido-editor/partido-editor.component';
import { PartidoEstadisticasComponent } from './partidos/partido-estadisticas/partido-estadisticas.component';
import { PartidoListaComponent } from './partidos/partido-lista/partido-lista.component';
import { PartidoVeedorComponent } from './partidos/partido-veedor/partido-veedor.component';
import { HomeComponent } from './portales/home/home.component';
import { RecintoDetalleComponent } from './recintos/recinto-detalle/recinto-detalle.component';
import { RecintoListaComponent } from './recintos/recinto-lista/recinto-lista.component';
import { UsuarioIngresoComponent } from './usuarios/usuario-ingreso/usuario-ingreso.component';
import { UsuarioRegistroComponent } from './usuarios/usuario-registro/usuario-registro.component';
import { MisInvitacionesComponent } from './jugadores/mis-invitaciones/mis-invitaciones.component';
import { CampeonatoDetalleComponent } from './campeonatos/campeonato-detalle/campeonato-detalle.component';
import { CampeonatoListaComponent } from './campeonatos/campeonato-lista/campeonato-lista.component';
import { CampeonatoEditorComponent } from './campeonatos/campeonato-editor/campeonato-editor.component';
import { ResponderInvitacionComponent } from './jugadores/responder-invitacion/responder-invitacion.component';
import { EquipoEliminarComponent } from './equipos/equipo-eliminar/equipo-eliminar.component';
import { RecintoEditorComponent } from './recintos/recinto-editor/recinto-editor.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: IndexComponent
      },
      {
        path: 'usuario/registro',
        component: UsuarioRegistroComponent
      },
      {
        path: 'usuario/ingreso',
        component: UsuarioIngresoComponent
      },
      {
        path: 'partidos/:pagina',
        component: PartidoListaComponent
      },
      {
        path: 'partido/:id',
        component: PartidoDetalleComponent
      },
      {
        path: 'partido/:accion/:id',
        component: PartidoEditorComponent
      },
      {
        path: 'partido/:id/veedor',
        component: PartidoVeedorComponent
      },
      {
        path: 'partido/stats/:id',
        component: PartidoEstadisticasComponent
      },
      {
        path: 'equipos/:pagina',
        component: EquipoListaComponent
      },
      {
        path: 'equipo/:id',
        component: EquipoDetalleComponent
      },
      {
        path: 'equipo/editor/:accion/:id',
        component: EquipoEditorComponent
      },
      {
        path: 'equipo/eliminar/:id',
        component: EquipoEliminarComponent
      },
      {
        path: 'jugadores/:pagina',
        component: JugadorListaComponent
      },
      {
        path: 'jugador/:id',
        component: JugadorDetalleComponent
      },
      {
        path: 'jugador/editor/:accion/:id',
        component: JugadorEditorComponent
      },
      {
        path: 'recintos/:pagina',
        component: RecintoListaComponent
      },
      {
        path: 'recinto/:id',
        component: RecintoDetalleComponent
      },
      {
        path: 'recinto/:accion/:id',
        component: RecintoEditorComponent
      },
      { 
        path: 'mi-perfil',
        component: MiPerfilComponent
      },
      { 
        path: 'mis-invitaciones',
        component: MisInvitacionesComponent
      },
      { 
        path: 'responder-invitacion/:id/:respuesta',
        component: ResponderInvitacionComponent
      },
      { 
        path: 'info/articulo/:id',
        component: ArticuloDetalleComponent
      },
      { 
        path: 'info/articulos-lista/:categoria',
        component: ArticuloListaComponent
      },
      {
        path: 'campeonatos/:pagina',
        component: CampeonatoListaComponent
      },
      {
        path: 'campeonato/:id',
        component: CampeonatoDetalleComponent
      },
      {
        path: 'campeonato/:accion/:id',
        component: CampeonatoEditorComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
