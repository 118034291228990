export enum AutorizacionPartidoEnum
{
    Indefinida = 0,
    Administrador = 1,
    Relator = 2,
    CapitanLocal = 3,
    CapitanVisita = 4,
    VeedorLocal = 5,
    VeedorVisita = 6,
    Arbitro = 7,
    Lineman = 8
}