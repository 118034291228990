import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecintosService } from 'src/services/servicio.recintos';
import { PartidosService } from '../../../services/servicio.partidos';

@Component({
  selector: 'app-partido-veedor',
  templateUrl: './partido-veedor.component.html',
  styleUrls: ['./partido-veedor.component.css']
})
export class PartidoVeedorComponent implements OnInit {
  public cantidadColumnas: number;


  public partido: any;
  public nominaLocal: Array<any>;
  public nominaVisita: Array<any>;

  public estadisticasPartido: any;
  public tieneLaPosesion: any;
  public equipoConPosesion: any;
  public estaRegistrando: boolean;
  public panelDisparoActivo: boolean;

  constructor(
    private partidoService: PartidosService,
    private recintosService: RecintosService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.estaRegistrando = true;
    this.tieneLaPosesion = { id: '' };
    this.equipoConPosesion = { id: '' };
    this.cantidadColumnas = 1;
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.partidoService.getPartido(id).subscribe(res => {
        if (res && res.esCorrecto) {
          this.partido = res.datos;
          console.log(this.partido);
          this.nominaLocal = this.partido.invitaciones.filter((el) => el.equipoId == this.partido.local.id);
          this.nominaVisita = this.partido.invitaciones.filter((el) => el.equipoId == this.partido.visita.id);
          this.partidoService.getEstadisticasPartido(id).subscribe(res2 => { 
            this.estadisticasPartido = res2.datos;
            console.log(this.estadisticasPartido);
          });
        }
      })
    });
  }

  registrar(jugador, equipo): void {
    this.panelDisparoActivo = false;
    if (this.tieneLaPosesion.id == '') {
      console.log("Inicia jugada");
      this.equipoConPosesion = equipo;
      this.tieneLaPosesion = jugador;
    }
    else {
      if (this.equipoConPosesion.id == equipo.id) {
        console.log("Equipo mantiene la posesion");
        console.log("Registrar pase", this.tieneLaPosesion, jugador);
        let accion = {
          partidoId: this.partido.id,
          equipoId: equipo.id,
          jugadorId: jugador.id,
          accion: 4 //pase
        };
        this.partidoService.registrarAccion(accion).subscribe((a) => { console.log(a); });
      }
      else {
        console.log("cambia la posesion");
        console.log("Registrar perdida ", this.tieneLaPosesion.id);
        let accionPerdida = {
          partidoId: this.partido.id,
          equipoId: this.equipoConPosesion.id,
          jugadorId: this.tieneLaPosesion.id,
          accion: 6 //perdida
        };
        console.log(accionPerdida);

        this.partidoService.registrarAccion(accionPerdida).subscribe((res) => {
          console.log("Registrar quite ", jugador.id);
          let accionQuite = {
            partidoId: this.partido.id,
            equipoId: equipo.id,
            jugadorId: jugador.id,
            accion: 5 //quite
          };
          console.log(accionQuite);
          this.partidoService.registrarAccion(accionQuite).subscribe((res2) => { });
        });

        this.equipoConPosesion = equipo;
      }
      this.tieneLaPosesion = jugador;
    }
  }

  perdida(jugador, equipo) : void {
    console.log("Registrar perdida ", this.tieneLaPosesion.id);
    let accionPerdida = {
      partidoId: this.partido.id,
      equipoId: equipo.id,
      jugadorId: jugador.id,
      accion: 6 //perdida
    };
    this.partidoService.registrarAccion(accionPerdida).subscribe((res) => {});
    this.tieneLaPosesion = { id: '' };
    this.equipoConPosesion = { id: '' };
  }

  disparo(jugador, equipo) : void {
    console.log("Registrar disparo ", this.tieneLaPosesion.id);
    let accionDisparo = {
      partidoId: this.partido.id,
      equipoId: equipo.id,
      jugadorId: jugador.id,
      accion: 3 //tiro a porteria
    };
    this.partidoService.registrarAccion(accionDisparo).subscribe((res) => {});
    this.tieneLaPosesion = { id: '' };
    this.equipoConPosesion = { id: '' };
  }

  gol(jugador, equipo) : void {
    console.log("Registrar gol ", this.tieneLaPosesion.id);
    let accionGol = {
      partidoId: this.partido.id,
      equipoId: equipo.id,
      jugadorId: jugador.id,
      accion: 1 //gol
    };
    this.partidoService.registrarAccion(accionGol).subscribe((res) => {});
    this.tieneLaPosesion = { id: '' };
    this.equipoConPosesion = { id: '' };
  }

  autogol(jugador, equipo) : void {
    console.log("Registrar autogol ", this.tieneLaPosesion.id);
    let accionGol = {
      partidoId: this.partido.id,
      equipoId: equipo.id,
      jugadorId: jugador.id,
      accion: 2 //gol
    };
    this.partidoService.registrarAccion(accionGol).subscribe((res) => {});
    this.tieneLaPosesion = { id: '' };
    this.equipoConPosesion = { id: '' };
  }

  tabClick(evento: any) : void {
    console.log(evento);
    if (evento.index == 1) {
      this.partidoService.getEstadisticasPartido(this.partido.id).subscribe(res2 => { 
        this.estadisticasPartido = res2.datos;
        console.log(this.estadisticasPartido);
      });
    }
  }

  onResize(event) : void {
    console.log(event.target.innerWidth);
    this.cantidadColumnas = (event.target.innerWidth > 700) ? 2 : 1;
  }

  invitar(equipo: any) : void {
    console.log(equipo);
  }

}
