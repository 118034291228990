import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-jugador-detalle',
  templateUrl: './jugador-detalle.component.html',
  styleUrls: ['./jugador-detalle.component.css']
})
export class JugadorDetalleComponent implements OnInit {
 
  public jugador: any;
  equipos: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jugadoresService: JugadoresService,
    private equiposService: EquiposService,
    private ubicacionesService: UbicacionesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.jugadoresService.getJugador(id).subscribe(res => {
        this.jugador = res.datos;
        console.log(this.jugador);
        let equipoIds = this.jugador.contratos.map(c => c.equipoId);
        this.equiposService.getMany(equipoIds).subscribe(res => {
          this.equipos = res.datos;
          console.log(this.equipos);
        });
      });
    });
  }


  
  obtenerEquipo(id: string) : any {
    let equipos = this.equipos.filter(m => m.id == id);
    if (equipos.length == 0 || equipos.length > 1)
      return { nombre: "", id: "" };
    return equipos[0];
  } 


  calcularEdad(fechaNac: string) : number {
    let fechaNacimiento = new Date(fechaNac);
    let timeDiff = Math.abs(Date.now() - fechaNacimiento.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    return age;
  }


  public obtenerUbicacion(ubicacionId: string) : any {
    return this.ubicacionesService.getUbicacion(ubicacionId);
  }
}
