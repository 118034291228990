import { Component, Input, OnInit } from '@angular/core';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';

@Component({
  selector: 'app-posicion-miniatura',
  templateUrl: './posicion-miniatura.component.html',
  styleUrls: ['./posicion-miniatura.component.css']
})
export class PosicionMiniaturaComponent implements OnInit {

  @Input()
  public posicion: any;

  constructor(
    private enumeracionesService: EnumeracionesService) { }

  ngOnInit(): void {
  }


  obtenerPosicion(posicion: number): string {
    return this.enumeracionesService.obtenerPosicion(posicion);
  }

}
