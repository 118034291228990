import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { RecintosService } from 'src/services/servicio.recintos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-equipo-lista',
  templateUrl: './equipo-lista.component.html',
  styleUrls: ['./equipo-lista.component.css']
})
export class EquipoListaComponent implements OnInit {
  columnasEquipos: string[] = ['nombreEquipo', 'ubicacionEquipo', 'fundacionEquipo', 'verMasEquipo'];
  resultados: any;
  pagina: number;
  largoPagina: number;
  filtro: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public equipoService: EquiposService,
    private jugadoresService: JugadoresService,
    private recintosService: RecintosService,
    private ubicacionesService: UbicacionesService
   ) { }

  ngOnInit(): void {
    this.pagina = 1;
    this.largoPagina = 10;
    this.filtro = "";
    this.route.params.subscribe(params => {
      var pagina = params['pagina'];
      this.reload();
      
    });
  }

  reload() : void {
    this.equipoService.getEquipos(this.filtro, this.pagina, this.largoPagina).subscribe(res => {
      this.resultados = res;
      console.log(this.resultados.items);
    });
  }



  paginadorListener(eventoPaginador: any) : void {
    console.log(eventoPaginador);
    eventoPaginador.previousPageIndex
    this.pagina = eventoPaginador.pageIndex + 1;
    this.largoPagina = eventoPaginador.pageSize;
    this.reload();
  }
  
  buscar() : void {
    this.pagina = 1;
    this.reload();    
  }
  
  public anterior(): void {
    if (this.pagina > 1)
    {
      this.pagina = this.pagina - 1;
      this.reload();
    }
  }

  public siguiente(): void {
    if (this.pagina > 0)
    {
      this.pagina = this.pagina + 1;
      this.reload();
    }
  }
}
