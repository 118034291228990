import { HttpInterceptor, HttpErrorResponse, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SesionService } from '../servicio.sesion';


@Injectable()
export class InterceptorAutentificacion implements HttpInterceptor {
    constructor(
        private router: Router,
        private sesionService: SesionService
        ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            console.log('No autorizado... mala cosa. Nos vemos.');
            // navigate /delete cookies or whatever
            localStorage.clear();
            this.router.navigate(['usuario/ingreso']);
            // if you've caught / handled the error, you don't want to rethrow
            // it unless you also want downstream consumers to have to handle it as well.
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        let authReq = req;

        let estaSesionActiva = this.sesionService.estaSesionActiva();
        
        if (estaSesionActiva) {
            let sesion = this.sesionService.obtenerSesion();
            if (sesion) {
                authReq = req.clone({headers: req.headers.set('token', sesion.token)});
            }
        }
        
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    }
}