import { Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class SesionService {
    urlApi: string;

    private get router() { return this.injector.get(Router); }

    constructor(
        private http: HttpClient,
        private injector: Injector
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }

        return new HttpHeaders(headerDict);
    }

    public login(usuario: any): Observable<any> {
        var options = {
            headers: this.headersGet()
        };

        localStorage.clear();
        return this.http.post(`${this.urlApi}/sesion/ingresar`, usuario, options);
    }

    public establecerSesion(sesion): void {
        localStorage.setItem('sessionTocala', JSON.stringify(sesion));
    }

    private getNowUTC() : Date {
        var localDate = new Date();

        return new Date(
            Date.UTC(
                localDate.getUTCFullYear(),
                localDate.getUTCMonth(),
                localDate.getUTCDate(),
                localDate.getUTCHours(),
                localDate.getUTCMinutes(),
                localDate.getUTCSeconds()
            )
        );
    }

    public estaSesionActiva(): boolean {
        let sessionTocala = localStorage.getItem('sessionTocala');
        if (sessionTocala) {
            let sesion = JSON.parse(sessionTocala);
            
            let expiracion = new Date(sesion.expiracion);
            let now = this.getNowUTC();

            if (expiracion > now) {
                return true;
            }
            else {
                console.log("expirado");
            }
        }
        else {
            console.log("deslogeado");
        }
        return false;
    }

    public obtenerSesion(): any {
        const sessionTocala = localStorage.getItem('sessionTocala');
        if (sessionTocala != null) {
            return JSON.parse(sessionTocala);
        } else {
            this.router.navigate(['']);
            localStorage.clear();
        }
        return null;
    }

    public salir(): any {
        
        var options = {
            headers: this.headersGet()
        };

        this.http.post(`${this.urlApi}/sesion/cerrarSesion`, {}, options).subscribe(res => {
            localStorage.clear();
            this.router.navigate(['']);
        });
    }
}