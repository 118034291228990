import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class MultimediaService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    private headersPostFile(): any {
        const headerDict = {
            "enctype": "multipart/form-data",
            'token': this.servicioSesiones.obtenerSesion().token
        }

        return new HttpHeaders(headerDict);
    }

    public crear(ubicacionEnBucket: any, fichero: any) : Observable<any> {
        var options =  {
            headers: this.headersPostFile()
        };
        const formData = new FormData();
        formData.append('ubicacionEnBucket', ubicacionEnBucket);
        formData.append('fichero', fichero, fichero.name);
        
        return this.http.post(`${this.urlApi}/Multimedia/Subir`, formData, options).pipe(map((res: Response) => res));
    }

    public eliminar(multimedia: any) : Observable<any> {
        var options =  {
            headers: this.headersPost(),
            body: multimedia
        };
        return this.http.delete(`${this.urlApi}/Multimedia`, options);
    }
}