<ng-container *ngIf="this.recinto">
    <div class="container px-4 py-5">
        <h2 class="pb-2 border-bottom"><span style="color: gray">Detalles del</span> Recinto</h2>
        <div class="row">
            <div class="col-sm-6 p-4 alias"><span class="small">nombre</span><br><i class="fa fa-id-card"></i> {{ this.recinto.nombre }} </div>
            <div class="col-sm-6 col-md-4  p-4 ubicacion">
                <span class="small">ubicación</span><br>
                <i class="fa fa-location-pin"></i> {{ obtenerUbicacion(this.recinto.ubicacion.comuna).nombre }}
            </div>
            <div class="col-sm-6 p-4 alias"><span class="small">descripcion</span><br><i class="fa fa-envelope"></i> {{ this.recinto.descripcion }}</div>
            <div class="col-sm-6 col-md-4 p-4 edad">
                <p>{{ this.recinto.ubicacion.direccion }}</p>
                <a class="btn btn-primary" target="_blank" href="http://maps.google.com/maps?z=12&t=m&q=loc:{{ this.recinto.ubicacion.latitud }}+{{ this.recinto.ubicacion.longitud }}">Abrir en google maps</a> 
                
            </div>
            <!-- <div class="col-sm-12">
                AQUI EL MAPA
            </div> -->
        </div>
    </div>
</ng-container>