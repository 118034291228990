import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecintoEditorComponent } from './recinto-editor/recinto-editor.component';
import { RecintoDetalleComponent } from './recinto-detalle/recinto-detalle.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';
import { RecintoListaComponent } from './recinto-lista/recinto-lista.component';

@NgModule({
  declarations: [RecintoEditorComponent, RecintoDetalleComponent, RecintoListaComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    FormsModule
  ],
  providers: [EnumeracionesService, UbicacionesService]
})
export class RecintosModule { }
