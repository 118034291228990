import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class EquiposService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getEquipos(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/equipo/Buscar`, { keywords: filtro.split(' '), largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getEquiposPorComuna(comuna: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/equipo/PorComuna`, { keywords: [ comuna ], largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getEquipo(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/equipo/${id}`, options);
    }

    public getMany(ids: Array<string>) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        let url = `${this.urlApi}/equipo/getmany/?ids=${ids.join(",")}`;
        return this.http.get(url, options);
    }

    public crear(equipo: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        return this.http.put(`${this.urlApi}/equipo`, equipo, options);
    }

    public actualizar(equipo: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        return this.http.post(`${this.urlApi}/equipo`, equipo, options);
    }

    public modificarContratacion(equipo: any, contratacion: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        return this.http.post(`${this.urlApi}/equipo/contratacion/${equipo.id}`, contratacion, options);
    }

    public crearContratacion(equipo: any, contratacion: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        return this.http.put(`${this.urlApi}/equipo/contratacion/${equipo.id}`, contratacion, options);
    }

    public eliminarContratacion(equipo: any, contratacion: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        return this.http.delete(`${this.urlApi}/equipo/${equipo.id}/contratacion/${contratacion.id}`, options);
    }

    public eliminar(equipo: any) : Observable<any> {
        var options =  {
            headers: this.headersPost(),
        };
        return this.http.delete(`${this.urlApi}/equipo/${equipo.id}`, options);
    }

}