<div class="shadow-sm p-3 mb-5 bg-white rounded">
    <table *ngIf="partido" style="width: 100%">
        <tr>
            <td style="width: 30%; text-align: center;">
                <img *ngIf="partido.local.urlLogo" style="width: 100px; height: 100px;" src="{{partido.local.urlLogo}}">
                <img *ngIf="!partido.local.urlLogo" style="width: 100px; height: 100px;"
                    src="/assets/img/equipoLocal.png"><br>
                <a class="team-name" [routerLink]="['/equipo', partido.local.id]">{{ partido.local.alias }}</a>
            </td>
            <td style="width: 40%; text-align: center;">
                <span class="match-state" *ngIf="this.obtenerEstadoPartido() != 'Desconocido'">{{
                    this.obtenerEstadoPartido() }}<br></span>
                <ng-container *ngIf="!this.estaFinalizado()">
                    <span class="match-date">{{ this.partido.horario | date: 'MMM d, y' }}</span><br>
                    <span class="match-time">{{ this.partido.horario | date: 'h:mm a' }}</span><br>
                </ng-container>
                <ng-container *ngIf="this.estaFinalizado() && this.partido.resultado">
                    <h3 class="p-0 m-0">
                        {{ this.partido.resultado.local }} - {{ this.partido.resultado.visita }}
                    </h3>
                    <span class="match-date">{{ this.partido.horario | date: 'MMM d, y' }}</span><br>
                </ng-container>
                <span class="match-place"><a [routerLink]="['/recinto', this.partido.estadio.id]">{{
                        this.partido.estadio.nombre }}</a></span>
                <button *ngIf="!this.esconderVerMas" [routerLink]="['/partido', partido.id]"
                    class="btn btn-sm btn-secondary match-seemore"
                    style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">Ver
                    mas</button>
            </td>
            <td style="width: 30%; text-align: center;">
                <img *ngIf="partido.visita.urlLogo || partido.visita.urlLogo != ''" style="width: 100px; height: 100px;"
                    src="{{partido.visita.urlLogo}}">
                <img *ngIf="!(partido.visita.urlLogo || partido.visita.urlLogo != '')"
                    style="width: 100px; height: 100px;" src="/assets/img/equipoVisita.png"><br>
                <a class="team-name" [routerLink]="['/equipo', partido.visita.id]">{{ partido.visita.alias }}</a>
            </td>
        </tr>
    </table>
</div>