import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquiposService } from 'src/services/servicio.equipos';
import { InvitacionesService } from 'src/services/servicio.invitaciones';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { PartidosService } from 'src/services/servicio.partidos';

@Component({
  selector: 'app-mis-invitaciones',
  templateUrl: './mis-invitaciones.component.html',
  styleUrls: ['./mis-invitaciones.component.css']
})
export class MisInvitacionesComponent implements OnInit {

  largoPagina: number;
  pagina: number;
  filtro: string;
  
  jugadorAutenticado: any;
  invitaciones: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jugadoresService: JugadoresService,
    private partidosService: PartidosService,
    private equipoService: EquiposService,
    private invitacionesService: InvitacionesService) { }

  ngOnInit(): void {
    this.largoPagina = 12;  
    this.pagina = 1;
    this.filtro = "";
    this.reload();
  }

  reload() : void {
    this.jugadoresService.jugadorAutenticado().subscribe(res => {
      this.jugadorAutenticado  = res.datos;
      this.invitacionesService.getInvitaciones(this.jugadorAutenticado.id, this.pagina, this.largoPagina).subscribe(
        res2 => {
          console.log("invitaciones", res2);
          this.invitaciones = res2.items;
          let partidosIds = res2.items.filter(p => p.tipo == 1).map(i => i.targetId);
          let equipoIds = res2.items.filter(p => p.tipo == 2).map(i => i.targetId);
          console.log("partidosIds", partidosIds);
          console.log("equipoIds", equipoIds);
          if (partidosIds.length > 0) {
            this.partidosService.getMany(partidosIds).subscribe(res3 => {
              console.log("partidos", res3);
              this.invitaciones.forEach(invitacion => {
                if (invitacion.tipo == 1)
                  invitacion.partido = this.obtenerPartidoPorId(res3.datos, invitacion.targetId)
              });
              },
              error => { console.log(error); }
            );
          }
          
          if (equipoIds.length > 0) {
            this.equipoService.getMany(equipoIds).subscribe(res4 => {
              console.log("equipos", res4);
              this.invitaciones.forEach(invitacion => {
                if (invitacion.tipo == 2)
                  invitacion.equipo = this.obtenerEquipoPorId(res4.datos, invitacion.targetId)
              });
            },
            error => { console.log(error); }
          );
          }
          
        },
        error => { console.log(error); }
      );
      console.log(this.jugadorAutenticado);
    });
  }

  obtenerPartidoPorId(partidos: Array<any>, partidoId: string) : any {
    if (partidos) {
      var encontrados = partidos.filter(p => p.id == partidoId);
      if (encontrados.length > 0){
        return encontrados[0];
      }
    }
    return null;
  }

  obtenerEquipoPorId(equipos: Array<any>, equipoId: string) : any {
    if (equipos) {
      var encontrados = equipos.filter(p => p.id == equipoId);
      if (encontrados.length > 0){
        return encontrados[0];
      }
    }
    return null;
  }
  
  buscar() : void {
    this.pagina = 1;
    this.reload();    
  }

  public anterior(): void {
    if (this.pagina > 1)
    {
      this.pagina = this.pagina - 1;
      this.reload();
    }
  }

  public siguiente(): void {
    if (this.pagina > 0)
    {
      this.pagina = this.pagina + 1;
      this.reload();
    }
  }
}
