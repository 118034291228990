import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { UsuariosService } from 'src/services/servicio.usuarios';

@Component({
  selector: 'app-usuario-registro',
  templateUrl: './usuario-registro.component.html',
  styleUrls: ['./usuario-registro.component.css']
})
export class UsuarioRegistroComponent implements OnInit {

  usuario: any;
  registroEnviado: boolean;
  estaRegistrando: boolean;
  error: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuariosService: UsuariosService,
    private jugadoresService: JugadoresService) { }

  ngOnInit(): void {
    this.estaRegistrando = false;
    this.usuario = { Email: "", Password: ""}
    this.error = { esVisible: false, mensajes: [] };
  }

  registrar() : void {
    this.estaRegistrando = true;
    this.usuariosService.registrar(this.usuario).subscribe(res => {
      if (res.esCorrecto) {
        this.registroEnviado = true;
        console.log("exito", res.datos);
      }
      else {
        this.error.esVisible = true;
        this.error.mensajes = res.mensajes;
        console.log("no se pudo crear el usuario", res);
      }
      this.estaRegistrando = false;
    })
  }

}
