<table width="100%" *ngIf="this.estadisticasPartido">
  <tr>
    <td>
      <h4>{{ this.estadisticasPartido.marcadorLocal }}</h4>
    </td>
    <td>Final</td>
    <td>
      <h4>{{ this.estadisticasPartido.marcadorVisita }}</h4>
    </td>
  </tr>
  <tr>
    <th>{{ this.partido.local.nombre }}</th>
    <th></th>
    <th>{{ this.partido.local.nombre }}</th>
  </tr>
  <tr>
    <td>{{ this.estadisticasPartido.pasesLocal }}</td>
    <td>Pases</td>
    <td>{{ this.estadisticasPartido.pasesVisita }}</td>
  </tr>
  <tr>
    <td>{{ this.estadisticasPartido.tirosPorteriaLocal }}</td>
    <td>Tiros</td>
    <td>{{ this.estadisticasPartido.tirosPorteriaVisita }}</td>
  </tr>
  <tr>
    <td>{{ this.estadisticasPartido.perdidasLocal }}</td>
    <td>Perdidas</td>
    <td>{{ this.estadisticasPartido.perdidasVisita }}</td>
  </tr>
  <tr>
    <td>{{ this.estadisticasPartido.quitesLocal }}</td>
    <td>Quites</td>
    <td>{{ this.estadisticasPartido.quitesVisita }}</td>
  </tr>
</table>

<br>
<br>
<br>
<br>
<hr>
<br>
<br>
<br>
<div *ngFor="let estadisticaJug of this.estadisticasJugadores">
  <h4>{{ estadisticaJug.jugador.nombre }}</h4>
  <table width="100%" *ngIf="this.estadisticasPartido">
    <tr>
      <td>Pases</td>
      <td>{{ estadisticaJug.pases }}</td>
    </tr>
    <tr>
      <td>Tiros</td>
      <td>{{ estadisticaJug.tirosPorteria }}</td>
    </tr>
    <tr>
      <td>Perdidas</td>
      <td>{{ estadisticaJug.perdidas }}</td>
    </tr>
    <tr>
      <td>Quites</td>
      <td>{{ estadisticaJug.quites }}</td>
    </tr>
    <tr>
      <td>Goles</td>
      <td>{{ estadisticaJug.goles }}</td>
    </tr>
  </table>
  <hr>
</div>