import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-horario',
  templateUrl: 'input-horario.html',
  styleUrls: ['input-horario.css'],
})

export class InputHorarioComponent {
  _value: any;

  hora: number;
  minuto: number;

  public horasPosibles: Array<Number> = [];
  public minutosPosibles: Array<Number> = [];

  constructor() {
    this.minuto = 0;
    this.hora = 0;
    this._value = this.obtenerTiempo();

    for (let hour=0; hour<24; hour++) {
      this.horasPosibles.push(hour);
    }
    for (let min=0; min<60; min++) {
      this.minutosPosibles.push(min);
    }
  }

  padWithZeros(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  
  obtenerTiempo() : any {
    return { horaString:this.padWithZeros(this.hora,2), hora: this.hora, minutosString: this.padWithZeros(this.minuto,2), minuto: this.minuto };
  }

  @Input() set valueHora(val: number) {
    this.hora = val;
    this._value = this.obtenerTiempo();
    this.valueChange.emit(this._value);
  }

  get valueHora(): number {
    return this.hora;
  }

  @Input() set valueMinutos(val: number) {
    this.minuto = val;
    this._value = this.obtenerTiempo();
    this.valueChange.emit(this._value);
  }
  
  get valueMinutos(): number {
    return this.minuto;
  }

  @Input() set value(val: any) {
    this._value = val;
    console.log(val);

    this.hora = val.hora;
    this.minuto = val.minuto;
    this.valueChange.emit(this._value);
  }

  get value(): any {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<string>();
  
}
