import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartidosService } from '../../../services/servicio.partidos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { SesionService } from 'src/services/servicio.sesion';
import { EquiposService } from 'src/services/servicio.equipos';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { AutorizacionPartidoEnum } from '../models/AutorizacionPartidoEnum';

@Component({
  selector: 'app-partido-detalle',
  templateUrl: './partido-detalle.component.html',
  styleUrls: ['./partido-detalle.component.css']
})
export class PartidoDetalleComponent implements OnInit {
  public partidoId: string;
  public partido: any;
  public nominaLocal: Array<any>;
  public nominaVisita: Array<any>;
  public estadisticasPartido: any;
  public jugadorIdSesion: string;
  public noEncontrado: boolean;
  public invitacionRemover: any;

  public filtroJugadorLocalTemporal: string;
  public filtroJugadorVisitaTemporal: string;
  public listaInvitadosLocalTemporal: Array<any>;
  public listaInvitadosVisitaTemporal: Array<any>;
  public invitacionEnCurso: boolean;

  public comentarioTemporal: string;
  public accionTemporal: any;
  public eliminarConfirmacionRelatoId: string;
  public eliminarConfirmacionComentarioId: string;

  public AutorizacionPartidoEnum = AutorizacionPartidoEnum;

  constructor(
    private partidoService: PartidosService,
    private jugadorService: JugadoresService,
    private equipoService: EquiposService,
    private sesionService: SesionService,
    public enumeracionesService: EnumeracionesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.filtroJugadorLocalTemporal = "";
    this.filtroJugadorVisitaTemporal = "";
    this.listaInvitadosLocalTemporal = null;
    this.listaInvitadosVisitaTemporal = null;
    this.invitacionEnCurso = false;
    this.accionTemporal = { accion: 1, jugadorId: '', minuto: 0 };
    this.comentarioTemporal = '';
    this.jugadorIdSesion = '';
    this.route.params.subscribe(params => {
      this.partidoId = params['id'];
      let sesionActiva = this.sesionService.estaSesionActiva();
      if (sesionActiva){
        let sesion = this.sesionService.obtenerSesion();
        this.jugadorIdSesion = sesion.usuarioAutenticado.entidades.filter(j => j.tipo == 0)[0].id;
        console.log(this.jugadorIdSesion);
      }
      this.recargar();
    });
  }

  recargar(): void {
    this.partidoService.getPartido(this.partidoId).subscribe(res => {
      if (res && res.esCorrecto) {
        this.filtroJugadorLocalTemporal = "";
        this.filtroJugadorVisitaTemporal = "";
        this.partido = res.datos;
        if (this.partido) {
          if (!this.partido.resultado) {
            this.partido.resultado = { local: 0, visita: 0 };
          }
          console.log(this.partido);
          this.nominaLocal = this.partido.invitaciones.filter((el) => el.equipoId == this.partido.local.id).sort((i1,i2) => i2.respuesta - i1.respuesta || i1.jugador.posicion - i2.jugador.posicion );
          this.nominaVisita = this.partido.invitaciones.filter((el) => el.equipoId == this.partido.visita.id).sort((i1,i2) =>  i2.respuesta - i1.respuesta || i1.jugador.posicion - i2.jugador.posicion);

          if (this.jugadorIdSesion) {
            this.partidoService.getEstadisticasPartido(this.partidoId).subscribe(res2 => {
              this.estadisticasPartido = res2.datos;
              console.log(this.estadisticasPartido);
            });
          }
        }
        else {
          this.noEncontrado = true;
        }
      }
    })
  }

  buscarJugadorVisita(): void {
    this.jugadorService.getJugadores(this.filtroJugadorVisitaTemporal.toLowerCase(), 1, 3).subscribe(res => {
      this.listaInvitadosVisitaTemporal = res.items;
      console.log(this.listaInvitadosVisitaTemporal);
    });
  }

  buscarJugadorLocal(): void {
    this.jugadorService.getJugadores(this.filtroJugadorLocalTemporal.toLowerCase(), 1, 3).subscribe(res => {
      this.listaInvitadosLocalTemporal = res.items;
      console.log(this.listaInvitadosLocalTemporal);
    });
  }

  invitarJugador(equipo: any, jugador: any): void {
    this.invitacionEnCurso = true;
    let invitacion = {
      jugador: jugador,
      equipoId: equipo.id,
      partidoId: this.partido.id
    };
    this.partidoService.invitar(invitacion).subscribe(res => {
      if (equipo.id == this.partido.local.id) {
        this.listaInvitadosLocalTemporal = this.listaInvitadosLocalTemporal.filter(j => j.id != jugador.id);
      }
      else {
        this.listaInvitadosVisitaTemporal = this.listaInvitadosVisitaTemporal.filter(j => j.id != jugador.id);
      }
      this.recargar();
      this.invitacionEnCurso = false;
    });
  }

  aceptarInvitacion(invitacion: any): void {
    invitacion.respuesta = 1;
    this.partidoService.responderInvitacion(invitacion).subscribe(res => {
      this.recargar();
    });
  }

  rechazarInvitacion(invitacion: any): void {
    invitacion.respuesta = 2;
    this.partidoService.responderInvitacion(invitacion).subscribe(res => {
      this.recargar();
    });
  }

  confirmarRemoverInvitacion(invitacion: any): void {
    this.invitacionRemover = invitacion;
  }

  removerInvitacion(): void {
    this.partidoService.eliminarInvitacion(this.invitacionRemover).subscribe(res => {
      if (res.esCorrecto) {
        this.recargar();
      }
      else {
        console.log("ERROR eliminando invitacion", this.invitacionRemover);
      }
    });
  }

  finalizarPartido(): void {
    this.partidoService.finalizar(this.partido).subscribe(res => {
      this.recargar();
    });
  }

  eliminarPartido(): void {
    this.partidoService.eliminar(this.partido).subscribe(res => {
      if (res.esCorrecto) {
        this.router.navigate(["/equipo", this.partido.local.id]);
      }
      else {
        alert(res.mensajes[0]);
      }
    });
  }

  actualizarInvitadosEquipoLocal(): void {
    this.listaInvitadosLocalTemporal = null;
    this.equipoService.getEquipo(this.partido.local.id).subscribe(res => {
      if (res.esCorrecto) {
        this.listaInvitadosLocalTemporal = [];
        let potencialesInvitados = res.datos.nomina.map(a => a.jugador);
        for (let i = 0; i < potencialesInvitados.length; i++) {
          let encontrado = this.partido.invitaciones.find(inv => inv.jugador.id === potencialesInvitados[i].id);
          if (!encontrado) {
            this.listaInvitadosLocalTemporal.push(potencialesInvitados[i]);
          }
        }
      }
    });
  }

  actualizarInvitadosEquipoVisita(): void {
    this.listaInvitadosVisitaTemporal = null;
    this.equipoService.getEquipo(this.partido.visita.id).subscribe(res => {
      if (res.esCorrecto) {
        this.listaInvitadosVisitaTemporal = [];
        let potencialesInvitados = res.datos.nomina.map(a => a.jugador);
        for (let i = 0; i < potencialesInvitados.length; i++) {
          let encontrado = this.partido.invitaciones.find(inv => inv.jugador.id === potencialesInvitados[i].id);
          if (!encontrado) {
            this.listaInvitadosVisitaTemporal.push(potencialesInvitados[i]);
          }
        }
      }
    });
  }

  comentar(): void {
    this.jugadorService.getJugador(this.jugadorIdSesion).subscribe(res => {
      let comentario = {
        partidoId: this.partido.id,
        texto: this.comentarioTemporal,
        timestamp: new Date(),
        jugador: res.datos
      };

      this.partidoService.comentar(comentario).subscribe(res => {
        if (res.esCorrecto) {
          console.log(res);
          this.comentarioTemporal = "";
          this.recargar();
        }
        else {
          console.log("ERROR ingresando comentario");
        }
      });
    });
  }

  eliminarComentario(comentario: any) : void {
    console.log(comentario);
    this.partidoService.removerComentario(comentario).subscribe(() => {
      this.recargar();
    });
  }

  estaFinalizado() : boolean {
    return this.partido.estado > 2;
  }

  agregarAccion() : void {
    var invitacion = this.partido.invitaciones.find(i => i.jugador.id == this.accionTemporal.jugadorId);
    console.log(invitacion);
    let accion = {
      accion: parseInt(this.accionTemporal.accion),
      minuto: parseInt(this.accionTemporal.minuto),
      jugadorId: this.accionTemporal.jugadorId,
      partidoId: this.partidoId,
      equipoId: invitacion.equipoId
    };
    
    this.partidoService.registrarAccion(accion).subscribe((resp) => {
      if (resp.esCorrecto){
        this.partido.acciones.push(resp.datos);
      }
      else {
        console.log(resp);
      }
    });
  }

  eliminarAccion(accion: any) : void {
    console.log(accion);
    this.partidoService.eliminarAccion(accion).subscribe(() => {
      this.recargar();
    });
  }

  obtenerNombreJugador(jugadorId: string) : string {
    var invitacion = this.partido.invitaciones.find(i => i.jugador.id == jugadorId);
    return invitacion.jugador.nombre + " " + invitacion.jugador.apellido;
  }

  estaAutorizado(rolPartido: number) : boolean {
    if (this.partido && this.partido.roles) {
      var roles = this.partido.roles.filter(r => r.ids.includes(this.jugadorIdSesion));
      if (roles.length > 0) {
        var roleFound = roles.filter(r => r.rol == rolPartido);
        return roleFound.length > 0;  
      }
    }
    return false;
  }

  contarPorEstado(nomina: Array<any>, estado: number) : number {
    return nomina.filter(i => i.respuesta === estado).length;
  }
}