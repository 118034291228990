import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticulosService } from 'src/services/servicio.articulos';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-articulo-detalle',
  templateUrl: './articulo-detalle.component.html',
  styleUrls: ['./articulo-detalle.component.css']
})
export class ArticuloDetalleComponent implements OnInit {

  public id: string;
  public articulo: any;

  public imagenPrincipal: any;
  public videoPrincipal: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicioArticulos: ArticulosService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      this.servicioArticulos.getArticulo(this.id).subscribe(res => {
        console.log(res);
        this.articulo = res.datos;
        this.imagenPrincipal = this.articulo.multimedia.filter(m => m.tipo == 1)[0];
        if (this.articulo.multimedia.filter(m => m.tipo == 2).length > 0){
          this.videoPrincipal = this.articulo.multimedia.filter(m => m.tipo == 2)[0];
          this.videoPrincipal.src = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoPrincipal.src);
        }
      });
    });
  }

  public toDateTime(secs) : Date {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

}
