import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipoDetalleComponent } from './equipo-detalle/equipo-detalle.component';
import { EquiposService } from 'src/services/servicio.equipos';
import { RouterModule } from '@angular/router';
import { EquipoListaComponent } from './equipo-lista/equipo-lista.component';
import { RecintosService } from 'src/services/servicio.recintos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';
import { SharedModule } from '../shared/shared.module';
import { EquipoEditorComponent } from './equipo-editor/equipo-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilidadesService } from 'src/services/servicio.utilidades';
import { EquipoEliminarComponent } from './equipo-eliminar/equipo-eliminar.component';


@NgModule({
  declarations: [EquipoDetalleComponent, EquipoListaComponent, EquipoEditorComponent, EquipoEliminarComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [EquiposService, RecintosService, UbicacionesService, UtilidadesService]
})
export class EquiposModule { }
