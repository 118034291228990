<div class="container">
    <main id="main">
        <ng-container *ngIf="this.jugador">
            <div class="px-4 py-5" id="custom-cards">
                <h2 class="pb-2 border-bottom"><span style="color: gray">{{ this.jugador.nombre }}</span> {{
                    this.jugador.apellido}} <app-posicion-miniatura [posicion]="this.jugador.posicion">
                    </app-posicion-miniatura>
                </h2>
                <div class="row pb-4">
                    <div class="col-sm-6 col-md-4 p-4 alias"><span class="small">alias</span><br>"{{ this.jugador.alias
                        }}"</div>
                    <div class="col-sm-6 col-md-4 p-4 posicion">
                        <span class="small">posición</span><br>
                        <app-posicion-miniatura [posicion]="this.jugador.posicion"></app-posicion-miniatura>
                    </div>
                    <div class="col-sm-6 col-md-4  p-4 ubicacion">
                        <span class="small">ubicación</span><br>
                        <i class="fa fa-location-pin"></i> {{ obtenerUbicacion(this.jugador.ubicacion.comuna).nombre }}
                    </div>
                    <div class="col-sm-6 col-md-4 p-4 edad">
                        <span class="small">edad</span><br>
                        <i class="fa fa-calendar"></i> {{
                        calcularEdad(this.jugador.fechaNacimiento)}} años
                    </div>
                    <div class="col-sm-6 col-md-4 p-4 estatura">
                        <span class="small">estatura</span><br>
                        <i class="fa fa-ruler-vertical"></i> {{ this.jugador.estatura }} cm.
                    </div>
                    <div class="col-sm-6 col-md-4 p-4 peso">
                        <span class="small">peso</span><br>
                        <i class="fa fa-weight-scale"></i> {{ this.jugador.peso }} kg.
                    </div>
                </div>
                <div class="row" *ngIf="this.equipos && this.equipos.length > 0">
                    <h4 class="pb-4 border-bottom">Equipos</h4>
                    <div *ngFor="let equipo of this.equipos" class="col-sm-12 col-md-6 col-lg-4 p-2">
                        <div class="shadow-sm p-3 mb-5 bg-white rounded">
                            <button class="btn btn-sm btn-secondary" style="float: right" [routerLink]="['/equipo', equipo.id]">Ver más</button>
                            <span class="equipo-alias"><a [routerLink]="['/equipo', equipo.id]">{{ equipo.alias }}</a></span><br>
                            <span class="border border-secondary rounded" style="background-color: {{ equipo.color }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;
                            <span class="border border-secondary rounded" style="background-color: {{ equipo.colorAlternativo }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span class="equipo-ubicacion">&nbsp;&nbsp;|&nbsp;{{ obtenerUbicacion(equipo.ubicacion.comuna).nombre }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!this.jugador">
            <div class="px-4 py-5" id="custom-cards">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </main>
</div>