import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticuloDetalleComponent } from './articulo-detalle/articulo-detalle.component';
import { ArticulosService } from 'src/services/servicio.articulos';
import { UsuariosService } from 'src/services/servicio.usuarios';
import { IndexComponent } from './index/index.component';
import { RouterModule } from '@angular/router';
import { ArticuloListaComponent } from './articulo-lista/articulo-lista.component';
import { FormsModule } from '@angular/forms';
import { SesionService } from 'src/services/servicio.sesion';



@NgModule({
  declarations: [ArticuloDetalleComponent, IndexComponent, ArticuloListaComponent],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule
  ],
  providers: [SesionService, UsuariosService, ArticulosService]
})
export class InfoModule { }
