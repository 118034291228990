<div class="container px-4 py-5">
    <main id="main">
        <div id="custom-cards">
            <h2 class="pb-2 border-bottom"><span>Partidos</span>
                <a style="float: right" [routerLink]="['/partido', 'crear', '0']"
                    class="btn btn-warning btn-sm">Crear</a>
            </h2>
            <!-- 
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" name="Buscar" placeholder="'Chile', 'Argentina', etc"
                            [(ngModel)]="this.filtro" required="">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.buscar()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row" *ngIf="this.resultados">
                <div class="col-md-6 col-sm-12" *ngFor="let partido of this.resultados.items">
                    <app-partido-miniatura [partido]="partido"></app-partido-miniatura>
                </div>
            </div>

            <div class="row" *ngIf="!this.resultados">
                <div class="col-sm-12">
                    <div class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" (click)="this.anterior()" ><a class="paginadorBtn" >Anterior</a></li>
                            <li><span style="font-size: 9pt; padding: 5px 20px">Pagina {{ this.pagina }}</span></li>
                            <li class="page-item" (click)="this.siguiente()"><a class="paginadorBtn" >Siguiente</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </main>
</div>