import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CampeonatoDetalleComponent } from './campeonato-detalle/campeonato-detalle.component';
import { CampeonatoListaComponent } from './campeonato-lista/campeonato-lista.component';
import { CampeonatoEditorComponent } from './campeonato-editor/campeonato-editor.component';
import { CampeonatosService } from 'src/services/servicio.campeonatos';
import { SesionService } from 'src/services/servicio.sesion';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [CampeonatoDetalleComponent, CampeonatoListaComponent, CampeonatoEditorComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  providers: [CampeonatosService, SesionService, UbicacionesService]
})
export class CampeonatosModule { }
