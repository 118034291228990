<div class="container px-4 py-5">
    <main id="main">

        <h2 class="pb-2 border-bottom"><span><span style="color: gray">Mis </span> Invitaciones</span></h2>

        <div class="row" *ngIf="this.invitaciones">
            <div class="col-md-6 col-sm-12" *ngFor="let invitacion of this.invitaciones">
                <ng-container *ngIf="invitacion.tipo == 1">
                
                    <div style="font-size: 10pt;">recibida: {{ invitacion.fechaCreacion | date: 'MMM d, y h:mm a' }}</div>
                    <app-partido-miniatura [partido]="invitacion.partido"></app-partido-miniatura>
                
            </ng-container>
            <ng-container *ngIf="invitacion.tipo == 2">
                <div style="font-size: 10pt;">recibida: {{ invitacion.fechaCreacion | date: 'MMM d, y h:mm a' }}</div>
                <app-equipo-miniatura [equipo]="invitacion.equipo"></app-equipo-miniatura>
            </ng-container>
            </div>
        </div>

        <div class="row p-3" *ngIf="!this.invitaciones">
            <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-12">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item" (click)="this.anterior()"><a class="btn btn-primary">Anterior</a></li>
                        <li><span style="font-size: 9pt; padding: 5px 20px">Pagina {{ this.pagina }}</span></li>
                        <li class="page-item" (click)="this.siguiente()"><a class="btn btn-primary">Siguiente</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </main>
</div>