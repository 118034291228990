import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/services/servicio.sesion';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

}
