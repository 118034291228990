<div class="container">
    <main id="main">
        <ng-container *ngIf="!this.equipo">
            <div class="px-4 py-5" id="custom-cards">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.equipo">
            <div class="pt-4">
                <h2 class="pb-2 border-bottom">{{ this.equipo.nombre }}
                    <div *ngIf="this.esJugadorCapitan(this.equipo)" class="btn-group" role="group" style="float: right;" >
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-gear"></i></button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" [routerLink]="['/equipo/editor/', 'modificar', this.equipo.id]"><i class="fa fa-edit"></i> Modificar</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/equipo/eliminar/', this.equipo.id]"><i class="fa fa-trash"></i> Eliminar</a></li>
                        </ul>
                    </div>
                </h2>

                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6 p-4 alias">
                                <span class="small">insignia</span><br>
                                <img *ngIf="this.equipo.urlLogo" width="100px" src="{{ this.equipo.urlLogo }}">
                                <ng-container *ngIf="this.esJugadorCapitan(this.equipo)">
                                    <br>
                                    <a class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#subirInsigniaModal">cambiar</a>
                                    <!-- Modal -->
                                    <div class="modal fade" id="subirInsigniaModal" tabindex="-1"
                                        aria-labelledby="subirInsigniaModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="subirInsigniaModalLabel">Subir
                                                        insignia
                                                    </h1>
                                                    <button type="button" class="btn-close" #cerrarModalSubirInsignia
                                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p *ngIf="this.logoResultado"><strong>{{ this.logoResultado
                                                            }}</strong></p>
                                                    <div class="row g-5 p-2">
                                                        <app-subir-fichero
                                                            [ubicacionEnBucket]="'equipos/' + this.equipo.id + '/insignia'"
                                                            (ficheroSubido)="logoSubidoListener($event)"></app-subir-fichero>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                        data-bs-dismiss="modal">Cerrar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6 p-4 alias">
                                <span class="small">alias</span><br>
                                <i class="fa fa-id-card"></i> {{ this.equipo.alias }}
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 p-4 alias">
                                <span class="small">emblema</span><br>
                                <i class="fa fa-quote-left"></i> {{ this.equipo.emblema }} <i
                                    class="fa fa-quote-right"></i>
                            </div>

                            <div class="col-sm-12  col-md-6 col-lg-6 p-4 colorP">
                                <span class="small">color principal</span><br>
                                <span class="border rounded"
                                    style="background-color: {{ this.equipo.color }}; padding: 0px 30px 0px 30px">&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <div class="col-sm-12  col-md-6 col-lg-6 p-4 colorA">
                                <span class="small">color alternativo</span><br>
                                <span class="border rounded"
                                    style="background-color: {{ this.equipo.colorAlternativo }}; padding: 0px 30px 0px 30px">&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <div class="col-sm-12  col-md-6 col-lg-6 p-4 fInscripcion">
                                <span class="small">fecha inscripción</span><br>
                                <i class="fa fa-calendar"></i> {{ this.equipo.fechaInscripcion | date:'MMM d, y'}}
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6 p-4 ubicacion">
                                <span class="small">ubicación</span><br>
                                <i class="fa fa-location-pin"></i> {{ obtenerUbicacion(equipo.ubicacion.comuna).nombre
                                }}
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6  p-4">
                        <span class="small">foto oficial</span><br>
                        <img width="100%" src="{{ this.equipo.urlEquipo }}">
                        <ng-container *ngIf="this.esJugadorCapitan(this.equipo)">
                            <br>
                            <a class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                data-bs-target="#subirFotoModal">cambiar</a>
                            <!-- Modal -->
                            <div class="modal fade" id="subirFotoModal" tabindex="-1"
                                aria-labelledby="subirFotoModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="subirFotoModalLabel">Subir Foto Principal
                                            </h1>
                                            <button type="button" class="btn-close" #cerrarModalSubirFoto
                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <p *ngIf="this.fotoPrincipalResultado"><strong>{{
                                                    this.fotoPrincipalResultado }}</strong></p>
                                            <div class="row g-5 p-2">
                                                <app-subir-fichero
                                                    [ubicacionEnBucket]="'equipos/' + this.equipo.id + '/foto'"
                                                    (ficheroSubido)="fotoPrincipalSubidaListener($event)"></app-subir-fichero>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"
                                                data-bs-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-sm-12 col-xs-12 pt-4">
                        <h2 class="pb-2 border-bottom">Nómina ({{ this.equipo.nomina.length }})
                            <button *ngIf="this.esJugadorCapitan(this.equipo)" data-bs-toggle="modal"
                                data-bs-target="#nuevaContratacionModal" (click)="this.nuevaContratacion()"
                                class="btn btn-sm btn-warning" style="float: right;">Contratar</button>
                        </h2>

                        <table class="table" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>Camiseta</th>
                                    <th>Nombre</th>
                                    <th>Edad</th>
                                    <th style="text-align: right;">Posición</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let posicion of ordenPosiciones">
                                    <tr *ngFor="let contratacion of obtenerJugadoresPorPosicion(this.equipo.nomina, posicion)">
                                        <td class="camiseta margin-bottom">
                                            <span>{{contratacion.numeroCamiseta}}</span>
                                        </td>
                                        <td class="nombre">
                                            <a [routerLink]="['/jugador', contratacion.jugador.id]">
                                                {{contratacion.jugador.nombre }} {{contratacion.jugador.apellido }}</a>
                                            &nbsp;
                                            <span *ngIf="contratacion.esCapitan" matTooltip="Capitán" style="color: white; 
                                        padding: 3px 5px 3px 5px;
                                        width: 70px;
                                        border-radius: 3px;
                                        background-color: #3f51b5; font-size: 8pt;">CAP</span>

                                        </td>
                                        <td>{{ calcularEdad(contratacion.jugador.fechaNacimiento) }}</td>
                                        <td class="posicion">
                                            <app-posicion-miniatura [posicion]="contratacion.posicion">
                                            </app-posicion-miniatura>
                                        </td>
                                        <td style="text-align: right;">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-danger btn-sm dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    *ngIf="this.esJugadorCapitan(this.equipo)">
                                                    <i class="fa fa-gear"></i>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li *ngIf="this.esJugadorCapitan(this.equipo)"><a class="dropdown-item"
                                                            data-bs-toggle="modal" data-bs-target="#contratacionModal"
                                                            (click)="this.modificarContratacion(contratacion)"><i
                                                                class="fa-solid fa-edit"></i> Modificar</a></li>
                                                    <li><a *ngIf="!contratacion.esCapitan" class="dropdown-item"
                                                            (click)="this.eliminarContratacion(contratacion)"><i
                                                                class="fa-solid fa-trash"></i>
                                                            Eliminar</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>


                        <!-- Modal -->
                        <div class="modal fade" id="contratacionModal" tabindex="-1"
                            aria-labelledby="contratacionModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="contratacionModalLabel">Modificando
                                            Contratación</h1>
                                        <button type="button" class="btn-close" #cerrarModalContratacion
                                            data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row g-5 p-2">
                                            <div class="col-md-7 col-lg-12" *ngIf="this.contratacionTemporal">
                                                <form class="needs-validation" novalidate="">
                                                    <div class="row g-3">
                                                        <div class="col-sm-6">
                                                            <label for="Camiseta" class="form-label">Nro.
                                                                Camiseta</label>
                                                            <input type="text" class="form-control" name="Camiseta"
                                                                placeholder="7, 11, 10, etc"
                                                                [(ngModel)]="this.contratacionTemporal.numeroCamiseta"
                                                                required="">
                                                            <div class="invalid-feedback">
                                                                Un numero de camiseta valido es requerido.
                                                            </div>
                                                        </div>


                                                        <div class="col-sm-6">
                                                            <label for="Posicion" class="form-label">Posicion
                                                                Preferida</label>
                                                            <select class="form-select" name="Posicion" required=""
                                                                [(ngModel)]="this.contratacionTemporal.posicion">
                                                                <option *ngFor="let posicion of this.posicionesPosibles"
                                                                    [ngValue]="posicion.key">{{posicion.value}}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                Por favor seleccione su posición preferida
                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-12">
                                            <label for="Email" class="form-label">Email <span class="text-muted">(Optional)</span></label>
                                            <input type="email" class="form-control" name="Email" placeholder="you@example.com"
                                              [(ngModel)]="this.jugador.email" disabled>
                                            <div class="invalid-feedback">
                                              Ingrese una dirección de correo electrónico valida.
                                            </div>
                                          </div> -->

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">Cerrar</button>
                                        <button type="button" class="btn btn-primary"
                                            (click)="this.guardarContratacion()">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="nuevaContratacionModal" tabindex="-1"
                            aria-labelledby="nuevaContratacionModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="nuevaContratacionModalLabel">Nueva
                                            Contratación</h1>
                                        <button type="button" class="btn-close" #cerrarModalNuevaContratacion
                                            data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row g-5">
                                            <div class="col-sm-12" *ngIf="this.nuevaContratacionTemporal">
                                                <form class="needs-validation" novalidate="">
                                                    <div class="row g-3">
                                                        <div class="col-sm-6">
                                                            <label for="Camiseta" class="form-label">Nro.
                                                                Camiseta</label>
                                                            <input type="text" class="form-control" name="Camiseta"
                                                                placeholder="7, 11, 10, etc"
                                                                [(ngModel)]="this.nuevaContratacionTemporal.numeroCamiseta"
                                                                required="">
                                                            <div class="invalid-feedback">
                                                                Un numero de camiseta valido es requerido.
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <label for="Posicion" class="form-label">Posicion
                                                                Preferida</label>
                                                            <select class="form-select" name="Posicion" required=""
                                                                [(ngModel)]="this.nuevaContratacionTemporal.posicion">
                                                                <option *ngFor="let posicion of this.posicionesPosibles"
                                                                    [ngValue]="posicion.key">{{posicion.value}}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                Por favor seleccione su posición preferida
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-12">
                                                            <label for="Jugador" class="form-label">Jugador</label>
                                                            <ng-container
                                                                *ngIf="!this.nuevaContratacionTemporal.jugador.id">
                                                                <div class="example-full-width" appearance="fill">
                                                                    <input type="text" name="filtroJugador"
                                                                        [(ngModel)]="this.filtroJugadorTemporal"
                                                                        class="form-control"
                                                                        placeholder="nombre@gmail.com" />
                                                                    <button class="btn btn-sm btn-primary"
                                                                        (click)="buscarJugador()"> <i
                                                                            class="fa fa-search"></i> Buscar</button>
                                                                </div>
                                                                <table style="width: 100%;"
                                                                    class="divle cdk-table ng-star-inserted">
                                                                    <tr
                                                                        *ngFor="let jugador of this.listaNuevaContratacionTemporal">
                                                                        <td style="text-align: justify">
                                                                            <a [routerLink]="['/jugador', jugador.id]">
                                                                                {{ jugador.nombre }} {{ jugador.apellido
                                                                                }}</a>
                                                                        </td>
                                                                        <td>
                                                                            <app-posicion-miniatura
                                                                                [posicion]="jugador.posicion">
                                                                            </app-posicion-miniatura>
                                                                        </td>
                                                                        <td>
                                                                            <button class="btn btn-sm btn-primary"
                                                                                (click)="seleccionarJugadorNuevaContratacion(jugador)">
                                                                                <i class="fa fa-plus"></i>
                                                                                Seleccionar</button>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="this.nuevaContratacionTemporal.jugador.id">
                                                                <div class="example-full-width" appearance="fill">
                                                                    <label>{{
                                                                        this.nuevaContratacionTemporal.jugador.nombre }}
                                                                        {{
                                                                        this.nuevaContratacionTemporal.jugador.apellido
                                                                        }}</label>
                                                                    <button class="btn btn-sm btn-primary"
                                                                        (click)="this.seleccionarJugadorNuevaContratacion({})">
                                                                        <i class="fa fa-search"></i> Cambiar</button>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                        <button type="button" class="btn btn-primary" [disabled]="!(this.nuevaContratacionTemporal.jugador && this.nuevaContratacionTemporal.jugador.id) ? true : null"
                                            (click)="this.crearContratacion()">Contratar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-lg-12  pt-4">
                        <h2 class="pb-2 border-bottom">Partidos
                            <button *ngIf="this.esJugadorCapitan(this.equipo)"
                                [routerLink]="['/partido/', 'crear', '0']" class="btn btn-sm btn-warning"
                                style="float: right;">Crear</button>
                        </h2>

                        <div class="row" *ngIf="this.ultimosPartidos">
                            <div class="col-md-6 col-sm-12" *ngFor="let partido of this.ultimosPartidos">
                                <app-partido-miniatura [partido]="partido"></app-partido-miniatura>
                            </div>
                        </div>
                        <div class="row" *ngIf="this.ultimosPartidos && this.ultimosPartidos.length < 1">
                            <p>Aun no existen partidos.</p>
                        </div>
                        <div class="row" *ngIf="!this.ultimosPartidos">
                            <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </ng-container>

    </main>
</div>