import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class UsuariosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersAutentificados() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersNoAutentificados() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
          
        return new HttpHeaders(headerDict);
    }

    public registrar(usuario: any) : Observable<any> {
        var options =  {
            headers: this.headersNoAutentificados()
        };
        return this.http.post(`${this.urlApi}/usuario/registrar`, usuario, options);
    }

    public nuevaContrasena(usuario: any) : Observable<any> {
        var options =  {
            headers: this.headersNoAutentificados()
        };
        return this.http.post(`${this.urlApi}/usuario/NuevaContrasena`, usuario, options);
    }
    
    public contacto(infoContacto: any) : Observable<any> {
        var options =  {
            headers: this.headersNoAutentificados()
        };
        return this.http.post(`${this.urlApi}/usuario/Contacto`, infoContacto, options);
    }

    public actualizar(usuario: any) : Observable<any> {
        var options =  {
            headers: this.headersAutentificados()
        };
        return this.http.post(`${this.urlApi}/usuario`, usuario, options);
    }
}