import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitacionesService } from 'src/services/servicio.invitaciones';

@Component({
  selector: 'app-responder-invitacion',
  templateUrl: './responder-invitacion.component.html',
  styleUrls: ['./responder-invitacion.component.css']
})
export class ResponderInvitacionComponent implements OnInit {

  respuesta: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invitacionesService: InvitacionesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let invitacionId = params['id'];
      this.respuesta = params['respuesta'];
      this.invitacionesService.responderInvitacion(invitacionId, this.respuesta).subscribe(res => {
          console.log(res);
          setTimeout(() => {this.router.navigate(["/partido", res.datos.targetId]);}, 4000);
        },
        error => { console.log(error); }
      );
    });
  }

}