import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { JugadorDetalleComponent } from './jugador-detalle/jugador-detalle.component';
import { JugadorListaComponent } from './jugador-lista/jugador-lista.component';
import { MiPerfilComponent } from './mi-perfil/mi-perfil.component';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { RouterModule } from '@angular/router';
import { JugadorEditorComponent } from './jugador-editor/jugador-editor.component';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MisInvitacionesComponent } from './mis-invitaciones/mis-invitaciones.component';
import { InvitacionesService } from 'src/services/servicio.invitaciones';
import { ResponderInvitacionComponent } from './responder-invitacion/responder-invitacion.component';

@NgModule({
  declarations: [JugadorDetalleComponent, JugadorListaComponent, MiPerfilComponent, JugadorEditorComponent, MisInvitacionesComponent, ResponderInvitacionComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    FormsModule
  ],
  providers: [JugadoresService, EnumeracionesService, InvitacionesService, DatePipe]
})
export class JugadoresModule { }
