import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecintosService } from 'src/services/servicio.recintos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-recinto-lista',
  templateUrl: './recinto-lista.component.html',
  styleUrls: ['./recinto-lista.component.css']
})
export class RecintoListaComponent implements OnInit {
  
  resultados: any;
  largoPagina: number;
  pagina: number;
  filtro: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public recintoService: RecintosService,
    public ubicacionesService: UbicacionesService) { }

  ngOnInit(): void {
    this.largoPagina = 12;  
    this.pagina = 1;
    this.filtro = "";
    this.route.params.subscribe(params => {
      var pagina = params['pagina'];
      this.reload();
    });
  }

  reload() : void {
    this.recintoService.getRecintos(this.filtro.toLowerCase(), this.pagina, this.largoPagina).subscribe(res => {
      this.resultados = res;
      console.log(this.resultados);
    });
  }
  
  public obtenerUbicacion(ubicacionId: string) : any {
    let ubicacion = this.ubicacionesService.getUbicacion(ubicacionId);
    return ubicacion;
  }
}
