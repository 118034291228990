import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from 'src/services/servicio.sesion';
import { UsuariosService } from 'src/services/servicio.usuarios';

@Component({
  selector: 'app-usuario-ingreso',
  templateUrl: './usuario-ingreso.component.html',
  styleUrls: ['./usuario-ingreso.component.css']
})
export class UsuarioIngresoComponent implements OnInit {

  error: string;
  notificacion: string;
  usuario: any;
  panelIngreso: boolean;
  estaAutenticando: boolean;
  estaRecordando: boolean;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuariosService: UsuariosService,
    private sesionService: SesionService) {
      this.usuario = { Email: "", Password: "" };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        console.log('queryParams', params);
        console.log('returnUrl', params['returnUrl']);
        this.returnUrl = params['returnUrl'];

        let estaAutenticado = this.sesionService.estaSesionActiva();
        if (estaAutenticado) {
          if (this.returnUrl && this.returnUrl != '') {
            this.router.navigate([this.returnUrl], { relativeTo: this.route });
          }
          else {
            this.router.navigate(["mi-perfil"]);
          }
        }
        else {
          
          this.panelIngreso = true;
          this.estaAutenticando = false;
          this.estaRecordando = false;
        }
      }
    );
  }

  ingresar(): void {
    this.estaAutenticando = true;
    this.sesionService.login(this.usuario).subscribe(
      res => {
        if (res.esCorrecto) {
          this.sesionService.establecerSesion(res.datos);
          console.log("Bienvenido", res.datos);
          console.log('this.returnUrl:', this.returnUrl);
          if (this.returnUrl && this.returnUrl != '') {
            this.router.navigate([this.returnUrl], { relativeTo: this.route });
          }
          else {
            this.router.navigate(["mi-perfil"]);
          }

          this.estaAutenticando = false;
        }
        else {
          console.log("ERROR");
          console.log(res.mensajes);
          this.error = res.mensajes[0];
          this.estaAutenticando = false;
        }
      },
      error => {
        console.log("ERROR");
        console.log(error.statusText);
        this.error = error.statusText;
        this.estaAutenticando = false;
      });
  }

  olvidoContrasena(): void {
    this.panelIngreso = false;
  }

  reenviarContrasena(): void {
    this.estaRecordando = true;
    this.usuariosService.nuevaContrasena(this.usuario).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.usuario = { Email: this.usuario.Email, Password: "" };
        this.panelIngreso = true;
        this.notificacion = "Su nueva contraseña fue enviada exitosamente, revise su correo electrónico."
      }
      else {
        this.error = "No se pudo crear la nueva contraseña";
        console.log(res);
      }
      this.estaRecordando = false;
    })
  }

  volverAlIngreso(): void {
    this.panelIngreso = true;
  }
}
