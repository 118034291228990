<div class="container">
    <main id="main">
        <ng-container *ngIf="!this.equipo">
            <div class="px-4 py-5" id="custom-cards">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.noAutorizado">
            <div class="p-2">
                <p><strong>No autorizado</strong></p>
                <p>Debes ser el capitan del equipo para realizar esta accion.</p>
            </div>
        </ng-container>
        <ng-container *ngIf="this.equipo && !this.noAutorizado">
            <div class="p-2">
                <h2 class="pb-2 border-bottom">{{ this.equipo.nombre }}</h2>

                <p><strong>¿Esta seguro que desea eliminar este equipo?</strong></p>
                <p>Todas las contrataciones, historial de partidos, imágenes e información relacionada al equipo será eliminada de forma irreversible.</p>


                <div class="col-sm-12">
                    <button class="btn btn-warning" (click)="eliminar()">Si, Eliminar</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-secondary" [routerLink]="['/equipo',this.equipoId]">Regresar</button>
                  </div>
            </div>
        </ng-container>

    </main>
</div>