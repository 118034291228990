<div class="container">
    <main id="main">

        <div class="container px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom"><span>Equipos</span>
            </h2>

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" name="Buscar" placeholder="'Chile', 'Argentina', etc"
                            [(ngModel)]="this.filtro" required="">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.buscar()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="this.resultados">
                <div *ngFor="let equipo of this.resultados.items" class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <app-equipo-miniatura [equipo]="equipo"></app-equipo-miniatura>
                </div>
            </div>
            <div class="row" *ngIf="!this.resultados">
                <div class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" (click)="this.anterior()"><a class="btn btn-primary">Anterior</a></li>
                            <li><span style="font-size: 9pt; padding: 5px 20px">Pagina {{ this.pagina }}</span></li>
                            <li class="page-item" (click)="this.siguiente()"><a class="btn btn-primary">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </main>
</div>