<div class="container px-4 py-5">
  <main id="main">

    <div class="row g-5 p-2">
      <div class="col-md-12 col-lg-12" *ngIf="this.jugador">
        <h2 class="mb-3 border-bottom">Modificar Jugador</h2>
        <form class="needs-validation" novalidate="">
          <div class="row g-3">
            <div class="col-sm-6">
              <label for="Nombre" class="form-label">Nombre</label>
              <input type="text" class="form-control" name="Nombre" placeholder="'Marcelo', 'Ivan', etc"
                [(ngModel)]="this.jugador.nombre" required="">
              <div class="invalid-feedback">
                Un nombre valido es requerido
              </div>
            </div>

            <div class="col-sm-6">
              <label for="Apellido" class="form-label">Apellido</label>
              <input type="text" class="form-control" name="Apellido" placeholder="'Salas', 'Zamorano', etc"
                [(ngModel)]="this.jugador.apellido" required="">
              <div class="invalid-feedback">
                Un apellido valido es requerido.
              </div>
            </div>

            <div class="col-sm-6">
              <label for="Alias" class="form-label">Alias</label>
              <div class="input-group has-validation">
                <input type="text" class="form-control" name="Alias" placeholder="'pelao', 'matador', etc"
                  [(ngModel)]="this.jugador.alias" required="">
                <div class="invalid-feedback">
                  Un alias corto es requerido.
                </div>
              </div>
            </div>


            <div class="col-sm-6">
              <label for="Posicion" class="form-label">Posicion Preferida</label>
              <select class="form-select" name="Posicion" required="" [(ngModel)]="this.jugador.posicion">
                <option *ngFor="let posicion of this.posicionesPosibles" [ngValue]="posicion.key">{{posicion.value}}
                </option>
              </select>
              <div class="invalid-feedback">
                Por favor seleccione su posición preferida
              </div>
            </div>

            <div class="col-12">
              <label for="Email" class="form-label">Email</label>
              <input type="email" class="form-control" name="Email" placeholder="you@example.com"
                [(ngModel)]="this.jugador.email" disabled>
              <div class="invalid-feedback">
                Ingrese una dirección de correo electrónico valida.
              </div>
            </div>

            <div class="col-sm-6">
              <label for="Ubicacion" class="form-label">Ubicación</label>
              <select class="form-select" name="Ubicacion" required="" [(ngModel)]="this.jugador.ubicacion.comuna">
                <option *ngFor="let ubicacion of this.ubicacionesPosibles" [ngValue]="ubicacion.key">{{ubicacion.value}}
                </option>
              </select>
              <div class="invalid-feedback">
                Por favor seleccione alguna ubicación válida
              </div>
            </div>

            <div class="col-sm-6">
              <label for="Fecha" class="form-label">Fecha Nacimiento</label>
              <div class="input-group mb-3">
                <input type="date" [(value)]="this.fechaNacSeleccionada" required pattern="\d{2}-\d{2}-\d{4}"
                  (change)="this.fechaNacCambiada($event)" class="form-control">
                <div class="invalid-feedback">
                  Una fecha de nacimiento valida es requerida.
                </div>
              </div>
            </div>

            <hr>

            <h4>Opcional</h4>

            <div class="col-sm-6">
              <label for="Peso" class="form-label">Peso <span class="text-muted">(kg)</span></label>
              <input type="text" class="form-control" name="Peso" placeholder="(en kilos)"
                [(ngModel)]="this.jugador.peso" required="">
              <div class="invalid-feedback">
                La peso es opcional pero es un detalle que los técnicos buscan saber. Ingrese la cantidad en kilos.
              </div>
            </div>

            <div class="col-sm-6">
              <label for="Estatura" class="form-label">Estatura <span class="text-muted">(cms)</span></label>
              <input type="text" class="form-control" name="Estatura" placeholder="(en centímetros)"
                [(ngModel)]="this.jugador.estatura" required="">
              <div class="invalid-feedback">
                La estatura es opcional pero es un detalle que los técnicos buscan saber. Ingrese la cantidad de
                centímetros.
              </div>
            </div>

            <hr class="my-4">
            <div class="my-3">
              <div class="form-check">
                <input name="aceptarPolitica" type="checkbox" class="form-check-input" required=""
                  [(ngModel)]="this.respuestaPoliticaPrivacidad">
                <label class="form-check-label" for="debit">Acepto la <a href="/info/articulo/politica-de-privacidad"
                    target="_blank">política de privacidad</a> del sitio.</label>
              </div>
            </div>
          </div>

          <div class="card text-bg-danger" *ngIf="this.mensajesError && this.mensajesError.length > 0">
            <div class="card-header">Ups!</div>
            <div class="card-body">
              <h5 class="card-title">Encontramos algunos problemas</h5>
              <ul>
                <li *ngFor="let mensajeError of this.mensajesError">{{ mensajeError }}</li>
              </ul>
            </div>
          </div>


          <button class="w-100 btn btn-primary btn-lg" type="submit" (click)="guardar()"
            [disabled]="!this.respuestaPoliticaPrivacidad">Guardar</button>
        </form>
      </div>
      <div class="col-md-12 col-lg-12" *ngIf="!this.jugador">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </main>
</div>