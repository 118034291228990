import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ArticulosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
    ) {
        this.urlApi = environment.apiLocation;
    }

    public getArticulos(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {};
        return this.http.post(`${this.urlApi}/articulo/Buscar`, { keywords: filtro.split(' '), largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getArticulosPorCategoria(categoria: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {};
        return this.http.post(`${this.urlApi}/articulo/Categoria`, { keywords: [ categoria ], largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getArticulo(id: string) : Observable<any> {
        var options =  {};
        return this.http.get(`${this.urlApi}/articulo/${id}`, options);
    }

    public getMany(ids: Array<string>) : Observable<any> {
        var options =  {};
        let url = `${this.urlApi}/articulo/getmany/?ids=${ids.join(",")}`;
        console.log(url);
        return this.http.get(url, options);
    }

}