import { Injectable } from '@angular/core';

@Injectable()
export class UbicacionesService {

    getUbicacion(idUbicacion: string) : any {
      return this.obtenerUbicacion(idUbicacion, this.pais[0]);
    }

    private obtenerUbicacion(idUbicacion: string, node: any) : any {
        if (node.id == idUbicacion)
            return node;
        else
        {
            let finded: any = null;
            for (let i=0; node.ubicaciones && i<node.ubicaciones.length; i++) {
                finded = this.obtenerUbicacion(idUbicacion, node.ubicaciones[i]);
                if (finded)
                    break;
            }
            return finded;
        }
    }

    public obtenerComunas() : Array<any> {
        let comunas = new Array<any>();
        for (let i=0; i<this.pais[0].ubicaciones.length; i++){

            for (let j=0; j<this.pais[0].ubicaciones[i].ubicaciones.length; j++){
                for (let k=0; k<this.pais[0].ubicaciones[i].ubicaciones[j].ubicaciones.length; k++){
                    comunas.push({
                        key: this.pais[0].ubicaciones[i].ubicaciones[j].ubicaciones[k].id,
                        value: this.pais[0].ubicaciones[i].ubicaciones[j].ubicaciones[k].nombre
                    });
                }
            }
        }
        return comunas.sort((one, two) => {
            return (one.value > two.value ? -1 : 1);
        }).reverse();
    }

    public obtenerRegiones() : Array<any> {
        return this.pais[0].ubicaciones;
        
    }

    public obtenerRegion(comunaId: string) : any {
        var region = this.getUbicacion(comunaId.split('-')[0]);
        return region;
    }
    
    private pais: Array<any> =
    [
        {
        "id": "56",
        "nombre": "Chile",
        "ubicaciones": [
            {
                "id": "15",
                "nombre": "Arica y Parinacota",
                "ubicaciones": [
                    {
                        "id": "15-1",
                        "nombre": "Arica",
                        "ubicaciones": [
                            { "id": "15-1-01", "nombre": "Arica" },
                            { "id": "15-1-02", "nombre": "Camarones" }
                        ]
                    },
                    {
                        "id": "15-2",
                        "nombre": "Parinacota",
                        "ubicaciones": [
                            { "id": "15-2-01", "nombre": "Putre" },
                            { "id": "15-2-02", "nombre": "General Lagos" }
                        ]
                    }
                ]
            },
            {
                "id": "01", "nombre": "Tarapacá", "ubicaciones": [
                    {
                        "id": "01-1", "nombre": "Iquique", "ubicaciones": [
                            { "id": "01-1-01", "nombre": "Iquique" },
                            { "id": "01-1-07", "nombre": "Alto Hospicio" }]
                    },
                    {
                        "id": "01-4", "nombre": "Tamarugal", "ubicaciones": [
                            { "id": "01-4-01", "nombre": "Pozo Almonte" },
                            { "id": "01-4-02", "nombre": "Camiña" },
                            { "id": "01-4-03", "nombre": "Colchane" },
                            { "id": "01-4-04", "nombre": "Huara" },
                            { "id": "01-4-05", "nombre": "Pica" }]
                    }],
            },
            {
                "id": "02", "nombre": "Antofagasta", "ubicaciones": [
                    {
                        "id": "02-1", "nombre": "Antofagasta", "ubicaciones": [
                            { "id": "02-1-01", "nombre": "Antofagasta" },
                            { "id": "02-1-02", "nombre": "Mejillones" },
                            { "id": "02-1-03", "nombre": "Sierra Gorda" },
                            { "id": "02-1-04", "nombre": "Taltal" }
                        ]
                    },
                    {
                        "id": "02-2", "nombre": "El Loa", "ubicaciones": [
                            { "id": "02-2-01", "nombre": "Calama" },
                            { "id": "02-2-02", "nombre": "Ollagüe" },
                            { "id": "02-2-03", "nombre": "San Pedro de Atacama" }]
                    },
                    {
                        "id": "02-3", "nombre": "Tocopilla", "ubicaciones": [
                            { "id": "02-3-01", "nombre": "Tocopilla" },
                            { "id": "02-3-02", "nombre": "María Elena" }]
                    }]
            },
            {
                "id": "03", "nombre": "Atacama", "ubicaciones": [
                    {
                        "id": "03-1", "nombre": "Copiapó", "ubicaciones": [
                            { "id": "03-1-01", "nombre": "Copiapó" },
                            { "id": "03-1-02", "nombre": "Caldera" },
                            { "id": "03-1-03", "nombre": "Tierra Amarilla" }]
                    },
                    {
                        "id": "03-2", "nombre": "Chañaral", "ubicaciones": [
                            { "id": "03-2-01", "nombre": "Chañaral" },
                            { "id": "03-2-02", "nombre": "Diego de Almagro" }]
                    },
                    {
                        "id": "03-3", "nombre": "Huasco", "ubicaciones": [
                            { "id": "03-3-01", "nombre": "Vallenar" },
                            { "id": "03-3-02", "nombre": "Alto del Carmen" },
                            { "id": "03-3-03", "nombre": "Freirina" },
                            { "id": "03-3-04", "nombre": "Huasco" }]
                    }]
            },
            {
                "id": "04", "nombre": "Coquimbo", "ubicaciones": [
                    {
                        "id": "04-1", "nombre": "Elqui", "ubicaciones": [
                            { "id": "04-1-01", "nombre": "La Serena" },
                            { "id": "04-1-02", "nombre": "Coquimbo" },
                            { "id": "04-1-03", "nombre": "Andacollo" },
                            { "id": "04-1-04", "nombre": "La Higuera" },
                            { "id": "04-1-05", "nombre": "Paiguano" },
                            { "id": "04-1-06", "nombre": "Vicuña" }]
                    },
                    {
                        "id": "04-2", "nombre": "Choapa", "ubicaciones": [
                            { "id": "04-2-01", "nombre": "Illapel" },
                            { "id": "04-2-02", "nombre": "Canela" },
                            { "id": "04-2-03", "nombre": "Los Vilos" },
                            { "id": "04-2-04", "nombre": "Salamanca" }]
                    },
                    {
                        "id": "04-3", "nombre": "Limarí", "ubicaciones": [
                            { "id": "04-3-01", "nombre": "Ovalle" },
                            { "id": "04-3-02", "nombre": "Combarbalá" },
                            { "id": "04-3-03", "nombre": "Monte Patria" },
                            { "id": "04-3-04", "nombre": "Punitaqui" },
                            { "id": "04-3-05", "nombre": "Río Hurtado" }]
                    }]
            },
            {
                "id": "05", "nombre": "Valparaíso", "ubicaciones": [
                    {
                        "id": "05-1", "nombre": "Valparaíso", "ubicaciones": [
                            { "id": "05-1-01", "nombre": "Valparaíso" },
                            { "id": "05-1-02", "nombre": "Casablanca" },
                            { "id": "05-1-03", "nombre": "Concón" },
                            { "id": "05-1-04", "nombre": "Juan Fernández" },
                            { "id": "05-1-05", "nombre": "Puchuncaví" },
                            { "id": "05-1-07", "nombre": "Quintero" },
                            { "id": "05-1-09", "nombre": "Viña del Mar" }]
                    },
                    {
                        "id": "05-2", "nombre": "Isla de Pascua", "ubicaciones": [
                            { "id": "05-2-01", "nombre": "Isla de Pascua" }]
                    },
                    {
                        "id": "05-3", "nombre": "Los Andes", "ubicaciones": [
                            { "id": "05-3-01", "nombre": "Los Andes" },
                            { "id": "05-3-02", "nombre": "Calle Larga" },
                            { "id": "05-3-03", "nombre": "Rinconada" },
                            { "id": "05-3-04", "nombre": "San Esteban" }]
                    },
                    {
                        "id": "05-4", "nombre": "Petorca", "ubicaciones": [
                            { "id": "05-4-01", "nombre": "La Ligua" },
                            { "id": "05-4-02", "nombre": "Cabildo" },
                            { "id": "05-4-03", "nombre": "Papudo" },
                            { "id": "05-4-04", "nombre": "Petorca" },
                            { "id": "05-4-05", "nombre": "Zapallar" }]
                    },
                    {
                        "id": "05-5", "nombre": "Quillota", "ubicaciones": [
                            { "id": "05-5-01", "nombre": "Quillota" },
                            { "id": "05-5-02", "nombre": "Calera" },
                            { "id": "05-5-03", "nombre": "Hijuelas" },
                            { "id": "05-5-04", "nombre": "La Cruz" },
                            { "id": "05-5-06", "nombre": "Nogales" }]
                    },
                    {
                        "id": "05-6", "nombre": "San Antonio", "ubicaciones": [
                            { "id": "05-6-01", "nombre": "San Antonio" },
                            { "id": "05-6-02", "nombre": "Algarrobo" },
                            { "id": "05-6-03", "nombre": "Cartagena" },
                            { "id": "05-6-04", "nombre": "El Quisco" },
                            { "id": "05-6-05", "nombre": "El Tabo" },
                            { "id": "05-6-06", "nombre": "Santo Domingo" }]
                    },
                    {
                        "id": "05-7", "nombre": "San Felipe de Aconcagua", "ubicaciones": [
                            { "id": "05-7-01", "nombre": "San Felipe" },
                            { "id": "05-7-02", "nombre": "Catemu" },
                            { "id": "05-7-03", "nombre": "Llaillay" },
                            { "id": "05-7-04", "nombre": "Panquehue" },
                            { "id": "05-7-05", "nombre": "Putaendo" },
                            { "id": "05-7-06", "nombre": "Santa María" }]
                    },
                    {
                        "id": "05-8", "nombre": "Marga Marga", "ubicaciones": [
                            { "id": "05-8-01", "nombre": "Quilpué" },
                            { "id": "05-8-02", "nombre": "Limache" },
                            { "id": "05-8-03", "nombre": "Olmué" },
                            { "id": "05-8-04", "nombre": "Villa Alemana" }]
                    }]
            },
            {
                "id": "06", "nombre": "Lib. Gral. Bernardo O'Higgins", "ubicaciones": [
                    {
                        "id": "06-1", "nombre": "Cachapoal", "ubicaciones": [
                            { "id": "06-1-01", "nombre": "Rancagua" },
                            { "id": "06-1-02", "nombre": "Codegua" },
                            { "id": "06-1-03", "nombre": "Coinco" },
                            { "id": "06-1-04", "nombre": "Coltauco" },
                            { "id": "06-1-05", "nombre": "Doñihue" },
                            { "id": "06-1-06", "nombre": "Graneros" },
                            { "id": "06-1-07", "nombre": "Las Cabras" },
                            { "id": "06-1-08", "nombre": "Machalí" },
                            { "id": "06-1-09", "nombre": "Malloa" },
                            { "id": "06-1-10", "nombre": "Mostazal" },
                            { "id": "06-1-11", "nombre": "Olivar" },
                            { "id": "06-1-12", "nombre": "Peumo" },
                            { "id": "06-1-13", "nombre": "Pichidegua" },
                            { "id": "06-1-14", "nombre": "Quinta de Tilcoco" },
                            { "id": "06-1-15", "nombre": "Rengo" },
                            { "id": "06-1-16", "nombre": "Requínoa" },
                            { "id": "06-1-17", "nombre": "San Vicente de Tagua Tagua" }]
                    },
                    {
                        "id": "06-2", "nombre": "Cardenal Caro", "ubicaciones": [
                            { "id": "06-2-01", "nombre": "Pichilemu" },
                            { "id": "06-2-02", "nombre": "La Estrella" },
                            { "id": "06-2-03", "nombre": "Litueche" },
                            { "id": "06-2-04", "nombre": "Marchihue" },
                            { "id": "06-2-05", "nombre": "Navidad" },
                            { "id": "06-2-06", "nombre": "Paredones" }]
                    },
                    {
                        "id": "06-3", "nombre": "Colchagua", "ubicaciones": [
                            { "id": "06-3-01", "nombre": "San Fernando" },
                            { "id": "06-3-02", "nombre": "Chépica" },
                            { "id": "06-3-03", "nombre": "Chimbarongo" },
                            { "id": "06-3-04", "nombre": "Lolol" },
                            { "id": "06-3-05", "nombre": "Nancagua" },
                            { "id": "06-3-06", "nombre": "Palmilla" },
                            { "id": "06-3-07", "nombre": "Peralillo" },
                            { "id": "06-3-08", "nombre": "Placilla" },
                            { "id": "06-3-09", "nombre": "Pumanque" },
                            { "id": "06-3-10", "nombre": "Santa Cruz" }]
                    }]
            },
            {
                "id": "07", "nombre": "Maule", "ubicaciones": [
                    {
                        "id": "07-1", "nombre": "Talca", "ubicaciones": [
                            { "id": "07-1-01", "nombre": "Talca" },
                            { "id": "07-1-02", "nombre": "Constitución" },
                            { "id": "07-1-03", "nombre": "Curepto" },
                            { "id": "07-1-04", "nombre": "Empedrado" },
                            { "id": "07-1-05", "nombre": "Maule" },
                            { "id": "07-1-06", "nombre": "Pelarco" },
                            { "id": "07-1-07", "nombre": "Pencahue" },
                            { "id": "07-1-08", "nombre": "Río Claro" },
                            { "id": "07-1-09", "nombre": "San Clemente" },
                            { "id": "07-1-10", "nombre": "San Rafael" }]
                    },
                    {
                        "id": "07-2", "nombre": "Cauquenes", "ubicaciones": [
                            { "id": "07-2-01", "nombre": "Cauquenes" },
                            { "id": "07-2-02", "nombre": "Chanco" },
                            { "id": "07-2-03", "nombre": "Pelluhue" }]
                    },
                    {
                        "id": "07-3", "nombre": "Curicó", "ubicaciones": [
                            { "id": "07-3-01", "nombre": "Curicó" },
                            { "id": "07-3-02", "nombre": "Hualañé" },
                            { "id": "07-3-03", "nombre": "Licantén" },
                            { "id": "07-3-04", "nombre": "Molina" },
                            { "id": "07-3-05", "nombre": "Rauco" },
                            { "id": "07-3-06", "nombre": "Romeral" },
                            { "id": "07-3-07", "nombre": "Sagrada Familia" },
                            { "id": "07-3-08", "nombre": "Teno" },
                            { "id": "07-3-09", "nombre": "Vichuquén" }]
                    },
                    {
                        "id": "07-4", "nombre": "Linares", "ubicaciones": [
                            { "id": "07-4-01", "nombre": "Linares" },
                            { "id": "07-4-02", "nombre": "Colbún" },
                            { "id": "07-4-03", "nombre": "Longaví" },
                            { "id": "07-4-04", "nombre": "Parral" },
                            { "id": "07-4-05", "nombre": "Retiro" },
                            { "id": "07-4-06", "nombre": "San Javier" },
                            { "id": "07-4-07", "nombre": "Villa Alegre" },
                            { "id": "07-4-08", "nombre": "Yerbas Buenas" }]
                    }]
            },
            {
                "id": "08", "nombre": "Biobío", "ubicaciones": [
                    {
                        "id": "08-1", "nombre": "Concepción", "ubicaciones": [
                            { "id": "08-1-01", "nombre": "Concepción" },
                            { "id": "08-1-02", "nombre": "Coronel" },
                            { "id": "08-1-03", "nombre": "Chiguayante" },
                            { "id": "08-1-04", "nombre": "Florida" },
                            { "id": "08-1-05", "nombre": "Hualqui" },
                            { "id": "08-1-06", "nombre": "Lota" },
                            { "id": "08-1-07", "nombre": "Penco" },
                            { "id": "08-1-08", "nombre": "San Pedro de La Paz" },
                            { "id": "08-1-09", "nombre": "Santa Juana" },
                            { "id": "08-1-10", "nombre": "Talcahuano" },
                            { "id": "08-1-11", "nombre": "Tomé" },
                            { "id": "08-1-12", "nombre": "Hualpén" }]
                    },
                    {
                        "id": "08-2", "nombre": "Arauco", "ubicaciones": [
                            { "id": "08-2-01", "nombre": "Lebu" },
                            { "id": "08-2-02", "nombre": "Arauco" },
                            { "id": "08-2-03", "nombre": "Cañete" },
                            { "id": "08-2-04", "nombre": "Contulmo" },
                            { "id": "08-2-05", "nombre": "Curanilahue" },
                            { "id": "08-2-06", "nombre": "Los Álamos" },
                            { "id": "08-2-07", "nombre": "Tirúa" }]
                    },
                    {
                        "id": "08-3", "nombre": "Biobío", "ubicaciones": [
                            { "id": "08-3-01", "nombre": "Los Ángeles" },
                            { "id": "08-3-02", "nombre": "Antuco" },
                            { "id": "08-3-03", "nombre": "Cabrero" },
                            { "id": "08-3-04", "nombre": "Laja" },
                            { "id": "08-3-05", "nombre": "Mulchén" },
                            { "id": "08-3-06", "nombre": "Nacimiento" },
                            { "id": "08-3-07", "nombre": "Negrete" },
                            { "id": "08-3-08", "nombre": "Quilaco" },
                            { "id": "08-3-09", "nombre": "Quilleco" },
                            { "id": "08-3-10", "nombre": "San Rosendo" },
                            { "id": "08-3-11", "nombre": "Santa Bárbara" },
                            { "id": "08-3-12", "nombre": "Tucapel" },
                            { "id": "08-3-13", "nombre": "Yumbel" },
                            { "id": "08-3-14", "nombre": "Alto Biobío" }]
                    },
                    {
                        "id": "08-4", "nombre": "Ñuble", "ubicaciones": [
                            { "id": "08-4-01", "nombre": "Chillán" },
                            { "id": "08-4-02", "nombre": "Bulnes" },
                            { "id": "08-4-03", "nombre": "Cobquecura" },
                            { "id": "08-4-04", "nombre": "Coelemu" },
                            { "id": "08-4-05", "nombre": "Coihueco" },
                            { "id": "08-4-06", "nombre": "Chillán Viejo" },
                            { "id": "08-4-07", "nombre": "El Carmen" },
                            { "id": "08-4-08", "nombre": "Ninhue" },
                            { "id": "08-4-09", "nombre": "Ñiquén" },
                            { "id": "08-4-10", "nombre": "Pemuco" },
                            { "id": "08-4-11", "nombre": "Pinto" },
                            { "id": "08-4-12", "nombre": "Portezuelo" },
                            { "id": "08-4-13", "nombre": "Quillón" },
                            { "id": "08-4-14", "nombre": "Quirihue" },
                            { "id": "08-4-15", "nombre": "Ránquil" },
                            { "id": "08-4-16", "nombre": "San Carlos" },
                            { "id": "08-4-17", "nombre": "San Fabián" },
                            { "id": "08-4-18", "nombre": "San Ignacio" },
                            { "id": "08-4-19", "nombre": "San Nicolás" },
                            { "id": "08-4-20", "nombre": "Treguaco" },
                            { "id": "08-4-21", "nombre": "Yungay" }]
                    }]
            },
            {
                "id": "09", "nombre": "La Araucanía", "ubicaciones": [
                    {
                        "id": "09-1", "nombre": "Cautín", "ubicaciones": [
                            { "id": "09-1-01", "nombre": "Temuco" },
                            { "id": "09-1-02", "nombre": "Carahue" },
                            { "id": "09-1-03", "nombre": "Cunco" },
                            { "id": "09-1-04", "nombre": "Curarrehue" },
                            { "id": "09-1-05", "nombre": "Freire" },
                            { "id": "09-1-06", "nombre": "Galvarino" },
                            { "id": "09-1-07", "nombre": "Gorbea" },
                            { "id": "09-1-08", "nombre": "Lautaro" },
                            { "id": "09-1-09", "nombre": "Loncoche" },
                            { "id": "09-1-10", "nombre": "Melipeuco" },
                            { "id": "09-1-11", "nombre": "Nueva Imperial" },
                            { "id": "09-1-12", "nombre": "Padre Las Casas" },
                            { "id": "09-1-13", "nombre": "Perquenco" },
                            { "id": "09-1-14", "nombre": "Pitrufquén" },
                            { "id": "09-1-15", "nombre": "Pucón" },
                            { "id": "09-1-16", "nombre": "Saavedra" },
                            { "id": "09-1-17", "nombre": "Teodoro Schmidt" },
                            { "id": "09-1-18", "nombre": "Toltén" },
                            { "id": "09-1-19", "nombre": "Vilcún" },
                            { "id": "09-1-20", "nombre": "Villarrica" },
                            { "id": "09-1-21", "nombre": "Cholchol" }]
                    },
                    {
                        "id": "09-2", "nombre": "Malleco", "ubicaciones": [
                            { "id": "09-2-01", "nombre": "Angol" },
                            { "id": "09-2-02", "nombre": "Collipulli" },
                            { "id": "09-2-03", "nombre": "Curacautín" },
                            { "id": "09-2-04", "nombre": "Ercilla" },
                            { "id": "09-2-05", "nombre": "Lonquimay" },
                            { "id": "09-2-06", "nombre": "Los Sauces" },
                            { "id": "09-2-07", "nombre": "Lumaco" },
                            { "id": "09-2-08", "nombre": "Purén" },
                            { "id": "09-2-09", "nombre": "Renaico" },
                            { "id": "09-2-10", "nombre": "Traiguén" },
                            { "id": "09-2-11", "nombre": "Victoria" }]
                    }]
            },
            {
                "id": "14", "nombre": "Los Ríos", "ubicaciones": [
                    {
                        "id": "14-1", "nombre": "Valdivia", "ubicaciones": [
                            { "id": "14-1-01", "nombre": "Valdivia" },
                            { "id": "14-1-02", "nombre": "Corral" },
                            { "id": "14-1-03", "nombre": "Lanco" },
                            { "id": "14-1-04", "nombre": "Los Lagos" },
                            { "id": "14-1-05", "nombre": "Máfil" },
                            { "id": "14-1-06", "nombre": "Mariquina" },
                            { "id": "14-1-07", "nombre": "Paillaco" },
                            { "id": "14-1-08", "nombre": "Panguipulli" }]
                    },
                    {
                        "id": "14-2", "nombre": "Ranco", "ubicaciones": [
                            { "id": "14-2-01", "nombre": "La Unión" },
                            { "id": "14-2-02", "nombre": "Futrono" },
                            { "id": "14-2-03", "nombre": "Lago Ranco" },
                            { "id": "14-2-04", "nombre": "Río Bueno" }]
                    }]
            },
            {
                "id": "10", "nombre": "Los Lagos", "ubicaciones": [
                    {
                        "id": "10-1", "nombre": "Llanquihue", "ubicaciones": [
                            { "id": "10-1-01", "nombre": "Puerto Montt" },
                            { "id": "10-1-02", "nombre": "Calbuco" },
                            { "id": "10-1-03", "nombre": "Cochamó" },
                            { "id": "10-1-04", "nombre": "Fresia" },
                            { "id": "10-1-05", "nombre": "Frutillar" },
                            { "id": "10-1-06", "nombre": "Los Muermos" },
                            { "id": "10-1-07", "nombre": "Llanquihue" },
                            { "id": "10-1-08", "nombre": "Maullín" },
                            { "id": "10-1-09", "nombre": "Puerto Varas" }]
                    },
                    {
                        "id": "10-2", "nombre": "Chiloé", "ubicaciones": [
                            { "id": "10-2-01", "nombre": "Castro" },
                            { "id": "10-2-02", "nombre": "Ancud" },
                            { "id": "10-2-03", "nombre": "Chonchi" },
                            { "id": "10-2-04", "nombre": "Curaco de Vélez" },
                            { "id": "10-2-05", "nombre": "Dalcahue" },
                            { "id": "10-2-06", "nombre": "Puqueldón" },
                            { "id": "10-2-07", "nombre": "Queilén" },
                            { "id": "10-2-08", "nombre": "Quellón" },
                            { "id": "10-2-09", "nombre": "Quemchi" },
                            { "id": "10-2-10", "nombre": "Quinchao" }]
                    },
                    {
                        "id": "10-3", "nombre": "Osorno", "ubicaciones": [
                            { "id": "10-3-01", "nombre": "Osorno" },
                            { "id": "10-3-02", "nombre": "Puerto Octay" },
                            { "id": "10-3-03", "nombre": "Purranque" },
                            { "id": "10-3-04", "nombre": "Puyehue" },
                            { "id": "10-3-05", "nombre": "Río Negro" },
                            { "id": "10-3-06", "nombre": "San Juan de la Costa" },
                            { "id": "10-3-07", "nombre": "San Pablo" }]
                    },
                    {
                        "id": "10-4", "nombre": "Palena", "ubicaciones": [
                            { "id": "10-4-01", "nombre": "Chaitén" },
                            { "id": "10-4-02", "nombre": "Futaleufú" },
                            { "id": "10-4-03", "nombre": "Hualaihué" },
                            { "id": "10-4-04", "nombre": "Palena" }]
                    }]
            },
            {
                "id": "11", "nombre": "Aysén del Gral. C. Ibáñez del Campo", "ubicaciones": [
                    {
                        "id": "11-1", "nombre": "Coyhaique", "ubicaciones": [
                            { "id": "11-1-01", "nombre": "Coyhaique" },
                            { "id": "11-1-02", "nombre": "Lago Verde" }]
                    },
                    {
                        "id": "11-2", "nombre": "Aysén", "ubicaciones": [
                            { "id": "11-2-01", "nombre": "Aysén" },
                            { "id": "11-2-02", "nombre": "Cisnes" },
                            { "id": "11-2-03", "nombre": "Guaitecas" }]
                    },
                    {
                        "id": "11-3", "nombre": "Capitán Prat", "ubicaciones": [
                            { "id": "11-3-01", "nombre": "Cochrane" },
                            { "id": "11-3-02", "nombre": "O'Higgins" },
                            { "id": "11-3-03", "nombre": "Tortel" }]
                    },
                    {
                        "id": "11-4", "nombre": "General Carrera", "ubicaciones": [
                            { "id": "11-4-01", "nombre": "Chile Chico" },
                            { "id": "11-4-02", "nombre": "Río Ibáñez" }]
                    }]
            },
            {
                "id": "12", "nombre": "Magallanes y Antártica Chilena", "ubicaciones": [
                    {
                        "id": "12-1", "nombre": "Magallanes", "ubicaciones": [
                            { "id": "12-1-01", "nombre": "Punta Arenas" },
                            { "id": "12-1-02", "nombre": "Laguna Blanca" },
                            { "id": "12-1-03", "nombre": "Río Verde" },
                            { "id": "12-1-04", "nombre": "San Gregorio" }]
                    },
                    {
                        "id": "12-2", "nombre": "Antártica Chilena", "ubicaciones": [
                            { "id": "12-2-01", "nombre": "Cabo de Hornos" },
                            { "id": "12-2-02", "nombre": "Antártica" }]
                    },
                    {
                        "id": "12-3", "nombre": "Tierra del Fuego", "ubicaciones": [
                            { "id": "12-3-01", "nombre": "Porvenir" },
                            { "id": "12-3-02", "nombre": "Primavera" },
                            { "id": "12-3-03", "nombre": "Timaukel" }]
                    },
                    {
                        "id": "12-4", "nombre": "Última Esperanza", "ubicaciones": [
                            { "id": "12-4-01", "nombre": "Natales" },
                            { "id": "12-4-02", "nombre": "Torres del Paine" }]
                    }]
            },
            {
                "id": "13", "nombre": "Metropolitana de Santiago", "ubicaciones": [
                    {
                        "id": "13-1", "nombre": "Santiago", "ubicaciones": [
                            { "id": "13-1-01", "nombre": "Santiago" },
                            { "id": "13-1-02", "nombre": "Cerrillos" },
                            { "id": "13-1-03", "nombre": "Cerro Navia" },
                            { "id": "13-1-04", "nombre": "Conchalí" },
                            { "id": "13-1-05", "nombre": "El Bosque" },
                            { "id": "13-1-06", "nombre": "Estación Central" },
                            { "id": "13-1-07", "nombre": "Huechuraba" },
                            { "id": "13-1-08", "nombre": "Independencia" },
                            { "id": "13-1-09", "nombre": "La Cisterna" },
                            { "id": "13-1-10", "nombre": "La Florida" },
                            { "id": "13-1-11", "nombre": "La Granja" },
                            { "id": "13-1-12", "nombre": "La Pintana" },
                            { "id": "13-1-13", "nombre": "La Reina" },
                            { "id": "13-1-14", "nombre": "Las Condes" },
                            { "id": "13-1-15", "nombre": "Lo Barnechea" },
                            { "id": "13-1-16", "nombre": "Lo Espejo" },
                            { "id": "13-1-17", "nombre": "Lo Prado" },
                            { "id": "13-1-18", "nombre": "Macul" },
                            { "id": "13-1-19", "nombre": "Maipú" },
                            { "id": "13-1-20", "nombre": "Ñuñoa" },
                            { "id": "13-1-21", "nombre": "Pedro Aguirre Cerda" },
                            { "id": "13-1-22", "nombre": "Peñalolén" },
                            { "id": "13-1-23", "nombre": "Providencia" },
                            { "id": "13-1-24", "nombre": "Pudahuel" },
                            { "id": "13-1-25", "nombre": "Quilicura" },
                            { "id": "13-1-26", "nombre": "Quinta Normal" },
                            { "id": "13-1-27", "nombre": "Recoleta" },
                            { "id": "13-1-28", "nombre": "Renca" },
                            { "id": "13-1-29", "nombre": "San Joaquín" },
                            { "id": "13-1-30", "nombre": "San Miguel" },
                            { "id": "13-1-31", "nombre": "San Ramón" },
                            { "id": "13-1-32", "nombre": "Vitacura" }]
                    },
                    {
                        "id": "13-2", "nombre": "Cordillera", "ubicaciones": [
                            { "id": "13-2-01", "nombre": "Puente Alto" },
                            { "id": "13-2-02", "nombre": "Pirque" },
                            { "id": "13-2-03", "nombre": "San José de Maipo" }]
                    },
                    {
                        "id": "13-3", "nombre": "Chacabuco", "ubicaciones": [
                            { "id": "13-3-01", "nombre": "Colina" },
                            { "id": "13-3-02", "nombre": "Lampa" },
                            { "id": "13-3-03", "nombre": "Tiltil" }]
                    },
                    {
                        "id": "13-4", "nombre": "Maipo", "ubicaciones": [
                            { "id": "13-4-01", "nombre": "San Bernardo" },
                            { "id": "13-4-02", "nombre": "Buin" },
                            { "id": "13-4-03", "nombre": "Calera de Tango" },
                            { "id": "13-4-04", "nombre": "Paine" }]
                    },
                    {
                        "id": "13-5", "nombre": "Melipilla", "ubicaciones": [
                            { "id": "13-5-01", "nombre": "Melipilla" },
                            { "id": "13-5-02", "nombre": "Alhué" },
                            { "id": "13-5-03", "nombre": "Curacaví" },
                            { "id": "13-5-04", "nombre": "María Pinto" },
                            { "id": "13-5-05", "nombre": "San Pedro" }]
                    },
                    {
                        "id": "13-6", "nombre": "Talagante", "ubicaciones": [
                            { "id": "13-6-01", "nombre": "Talagante" },
                            { "id": "13-6-02", "nombre": "El Monte" },
                            { "id": "13-6-03", "nombre": "Isla de Maipo" },
                            { "id": "13-6-04", "nombre": "Padre Hurtado" },
                            { "id": "13-6-05", "nombre": "Peñaflor" }]
                    }]
            },
            {
                "id": "99", "nombre": "No Encontrado", "ubicaciones": [
                    {
                        "id": "99-1", "nombre": "No Encontrado", "ubicaciones": [
                            { "id": "99-1-01", "nombre": "No Encontrado" }]
                    }]
            }
        ]
    }]

}