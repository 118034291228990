<!-- ======= Header ======= -->
<section id="topbar" class="topbar d-flex align-items-center">
    <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="social-links d-md-flex align-items-center">
            <a href="https://twitter.com/tocala_cl" target="_blank" class="twitter"><i class="fa-brands fa-twitter"></i></a>
            <a href="https://www.facebook.com/profile.php?id=100092621465273" target="_blank" class="facebook"><i class="fa-brands fa-facebook"></i></a>
            <a href="https://www.instagram.com/tocalachile/" target="_blank" class="instagram"><i class="fa-brands fa-instagram"></i></a>
        </div>
    </div>
</section><!-- End Top Bar -->

<nav class="navbar navbar-expand-md navbar-dark bg-dark navbar-tocala">
    <div class="container">
      <a class="navbar-brand" href="javascript:void()" [routerLink]="['/']"><h1>Tocala.cl<span style="color: #f85a40">.</span></h1></a>
      <button #menuPrincipalButton class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item" *ngIf="!this.estaActiva()"><a class="nav-link"  (click)="cerrarMenu()" [routerLink]="['/usuario/ingreso']">Ingresar</a></li>
            <li class="nav-item" *ngIf="this.estaActiva()"><a  class="nav-link"  (click)="cerrarMenu()" [routerLink]="['/mi-perfil']">Mi Perfil</a></li>
            <li class="nav-item" *ngIf="this.estaActiva()"><a  class="nav-link"  (click)="cerrarMenu()" [routerLink]="['/mis-invitaciones']">Invitaciones</a></li>
            <li class="nav-item" *ngIf="this.estaActiva()"><a  class="nav-link"  (click)="cerrarMenu()" [routerLink]="['/equipos', 1]">Equipos</a></li>
            <li class="nav-item"><a class="nav-link"  (click)="cerrarMenu()" [routerLink]="['/info/articulos-lista/', 'ayuda']">Ayuda</a></li>
            <li class="nav-item" *ngIf="this.estaActiva()"><a class="nav-link"  [routerLink]="['']" (click)="salir()">Cerrar Sesión</a></li>
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>

<!--header id="header" class="header d-flex align-items-center">

    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="javascript:void(0);" [routerLink]="['/']" class="logo d-flex align-items-center">
            <h1>Tocala.cl<span style="color: #f85a40">.</span></h1>
        </a>
        
        <nav id="navbar" class="navbar">
            <ul>
                <li *ngIf="!this.estaActiva()"><a (click)="cerrarMenu()" [routerLink]="['/usuario/ingreso']">Ingresar</a></li>
                <li *ngIf="this.estaActiva()"><a (click)="cerrarMenu()" [routerLink]="['/mi-perfil']">Mi Perfil</a></li>
                <li *ngIf="this.estaActiva()"><a (click)="cerrarMenu()" [routerLink]="['/equipos', 1]">Equipos</a></li>
                <li *ngIf="this.estaActiva()"><a (click)="cerrarMenu()" [routerLink]="['/jugadores', 1]">Jugadores</a></li>
                <li *ngIf="this.estaActiva()"><a (click)="cerrarMenu()" [routerLink]="['/partidos', 1]">Partidos</a></li>
                <li><a (click)="cerrarMenu()" [routerLink]="['/info/articulos-lista/', 'ayuda']">Ayuda</a></li>
                <li *ngIf="this.estaActiva()"><a [routerLink]="['']" (click)="salir()">Cerrar Sesión</a></li>
            </ul>
        </nav>

        <a href="javascript:void(0);" class="mobile-nav-toggle mobile-nav-show" style="font-size: 12pt; color: #ffffff; font-weight: 600; text-transform: uppercase;">
            <i class="fa-solid fa-bars"></i> Menu
        </a>
        
        <i #cerrarMenuIcon class="mobile-nav-toggle mobile-nav-hide d-none fa-solid fa-circle-xmark"></i>

    </div>
</header--><!-- End Header -->
<!-- End Header -->


<router-outlet>
</router-outlet>


<!-- ======= Footer ======= -->
<footer id="footer" class="footer">

    <div class="container">
        <div class="row gy-4">
            <div class="col-lg-6 col-md-12 footer-info">
                <a href="/" class="logo d-flex align-items-center">
                    <span>Tocala.cl</span>
                </a>
                <p>es un esfuerzo que intenta fomentar el deporte, y particularmente el fútbol amateur, proporcionándole herramientas 
                    de organización, comunicación e información que estimulen su práctica constante.</p>
                <div class="social-links d-flex mt-4">
                    <a href="https://twitter.com/tocala_cl" target="_blank" class="twitter"><i class="fa-brands fa-twitter"></i></a>
                    <a href="https://www.facebook.com/profile.php?id=100092621465273" target="_blank" class="facebook"><i class="fa-brands fa-facebook"></i></a>
                    <a href="https://www.instagram.com/tocalachile/" target="_blank" class="instagram"><i class="fa-brands fa-instagram"></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-6 footer-links">
                <h4>Enlaces</h4>
                <ul>
                    <li><a [routerLink]="['/']">Inicio</a></li>
                    <li><a [routerLink]="['/info/articulo', 'acerca-de']">Acerca de</a></li>
                    <li><a [routerLink]="['/info/articulos-lista/', 'ayuda']">Ayuda</a></li>
                    <li><a [routerLink]="['/info/articulo', 'politica-de-privacidad']">Privacidad</a></li>
                </ul>
            </div>

            <div class="col-lg-3 col-6 footer-links">
                <h4>Juega</h4>
                <ul>
                    <li *ngIf="this.estaActiva()"><a [routerLink]="['/mi-perfil']">Mi Perfil</a></li>
                    <li *ngIf="this.estaActiva()"><a [routerLink]="['/mis-invitaciones']">Invitaciones</a></li>
                    <li *ngIf="this.estaActiva()"><a [routerLink]="['/equipos', 1]">Equipos</a></li>
                    <li *ngIf="this.estaActiva()"><a [routerLink]="['/partidos', 1]">Partidos</a></li>
                    <li *ngIf="!this.estaActiva()"><a [routerLink]="['/usuario/ingreso']">Ingresar</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container mt-4">

        <div class="copyright">
            Desde Requínoa con el <i class="fa-solid fa-heart"></i> by <strong><span><a target="_blank" style="color: white" href="http://www.lintu.cl">Lintu</a></span></strong>.
            <br>All Rights Reserved
        </div>
    </div>

</footer><!-- End Footer -->
<!-- End Footer -->