import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';

@Component({
  selector: 'app-jugador-lista',
  templateUrl: './jugador-lista.component.html',
  styleUrls: ['./jugador-lista.component.css']
})
export class JugadorListaComponent implements OnInit {

  resultados: any;
  largoPagina: number;
  pagina: number;
  filtro: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public jugadoresService: JugadoresService,
    public enumeracionesService: EnumeracionesService
  ) { }

  ngOnInit(): void {
    this.largoPagina = 12;  
    this.pagina = 1;
    this.filtro = "";
    this.route.params.subscribe(params => {
      var pagina = params['pagina'];
      this.reload();
    });
  }

  reload() : void {
    this.jugadoresService.getJugadores(this.filtro.toLowerCase(), this.pagina, this.largoPagina).subscribe(res => {
      this.resultados = res;
      console.log(this.resultados);
    });
  }

  obtenerPosicion(posicion: number): string {
    return this.enumeracionesService.obtenerPosicion(posicion);
  }

  paginadorListener(eventoPaginador: any) : void {
    console.log(eventoPaginador);
    eventoPaginador.previousPageIndex
    this.pagina = eventoPaginador.pageIndex + 1;
    this.largoPagina = eventoPaginador.pageSize;
    this.reload();
  }
  
  buscar() : void {
    this.pagina = 1;
    this.reload();    
  }

  public anterior(): void {
    if (this.pagina > 1)
    {
      this.pagina = this.pagina - 1;
      this.reload();
    }
  }

  public siguiente(): void {
    if (this.pagina > 0)
    {
      this.pagina = this.pagina + 1;
      this.reload();
    }
  }
}
